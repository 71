import subscriptionMiddleware from '@/router/middleware/subscriptionMiddleware';
import Vue from 'vue';

export default [
  {
    path: "/financeiro",
    name: "Financeiro",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Financeiro",
      icon: "monetization_on",
      showLink: true,
      permission: () => Vue.$acl.canViewTransactions(),
    },
    component: () => import("@/views/financeiro/index.vue"),
  },
  {
    path: "/financeiro/relatorio-ir",
    name: "BillingExport",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "printLayout",
      name: "Relatório para fins de IR",
      permission: () => Vue.$acl.canViewTransactions(),
    },
    component: () => import("@/views/financeiro/BillingExport.vue"),
  },

  {
    path: "/financeiro/transacoes-pendentes",
    name: "PendingTransactions",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Transações Pendentes",
      permission: () => Vue.$acl.canViewTransactions(),
    },
    component: () => import("@/views/financeiro/PendingTransactions.vue"),
  },

]