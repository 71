import Vue from 'vue'

export default {

  namespaced: true,

  state: () => ({
    sessionId : null,
    activeCallUid : null,
    videoCallData: {}
  }),

  getters: {
    activeCallPath: state => {
      return 'sessions/' + state.sessionId + '/calls/' + state.activeCallUid 
    },
    

    incommingCallUid: state => {
      return state.videoCallData.activeCallUid// get from videoCallData 'sessions/' + state.sessionId + '/calls/' + state.callUid 
    },

    incommingCallPath:  (state, getters)  => {
      return 'sessions/' + state.sessionId + '/calls/' + getters.incommingCallUid 
    },

    hasIncommingCall : (state, getters)  => {
      return (getters.incommingCallUid != null)
    },

    incommingCall : (state, getters)  => {
      return (getters.incommingCallUid != null)
    },

    psycologistStatus: state => {
      return state.videoCallData.psycologistStatus
    },

    customerStatus: state => {
      return state.videoCallData.customerStatus
    },
  },

  mutations: {
    setSessionId(state, sessionId) {
      state.sessionId = sessionId
    },

    setVideoCallData(state, data) {
      state.videoCallData = data
    },

    setNewCall(state, uid) {
      state.activeCallUid = uid
    }
  },

  actions: {
    
  }
}