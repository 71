import subscriptionMiddleware from '@/router/middleware/subscriptionMiddleware';

export default [


  {
    path: "/ajuda",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Ajuda",
      icon: "contact_support",
      showLink: false,
    },
    component: () => import("@/views/ajuda/index.vue"),
  },
  {
    path: "/ajuda/validar-whatsapp",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Validação do WhatsApp",
    },
    component: () => import("@/views/ajuda/ValidacaodoWhatsApp.vue"),
  },
]
