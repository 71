import Vue from 'vue';
import { customerAlias } from "@/utils/customerAlias";

Vue.use({
  install(Vue) {
    Vue.prototype.$customer_alias = customerAlias()
    Vue.$customer_alias = customerAlias();
  }
})

export default (_) => { }