export default
  [
    {
      path: '/criar-conta',
      name: 'signUp',
      meta: { layout: 'authLayoutV2', auth: 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '@/views/auth/SignUpV2.vue')
    },
    // {
    //   path: '/criar-conta-v2',
    //   name: 'signUp2',
    //   meta: { layout: 'authLayoutV2', auth: 'guest' },
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/auth/SignUpV2.vue')
    // },
    // {
    //   path: '/m/criar-conta',
    //   name: 'signUpMobile',
    //   meta: { layout: 'setup-layout', auth: 'guest' },
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/auth/MobileSignUp.vue')
    // },
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'authLayoutV2', auth: 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '@/views/auth/LoginV2.vue')
    },
    // {
    //   path: '/login-v2',
    //   name: 'loginV2',
    //   meta: { layout: 'authLayoutV2', auth: 'guest' },
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/auth/LoginV2.vue')
    // },
    // {
    //   path: '/login/socialcallback',
    //   name: 'loginCallback',
    //   meta: { layout: 'authLayout', auth: 'guest' },
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/auth/LoginSocialCallback.vue')
    // },
    // {
    //   path: '/selecionar-empresa',
    //   name: 'companyAuth',
    //   meta: { layout: 'authLayout', auth: 'guest' }, // todo this causes loop (needs multiple middlewares)
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/CompanyAuth.vue')
    // },
    {
      path: '/recuperar-senha',
      name: 'recuperar-senha',
      meta: { layout: 'authLayout', auth: 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '@/views/auth/RecoverPassword.vue')
    },
    {
      path: '/redefinir-senha/:token',
      name: 'redefinir-senha',
      meta: { layout: 'authLayout', auth: 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '@/views/auth/ResetPassword.vue')
    },
    {
      path: '/convite/:token',
      name: 'convite',
      meta : { layout : 'authLayout', auth : 'guest' },
      component: () => import(/* webpackChunkName: "about" */ '@/views/auth/AcceptInvite.vue')
    },
    {
      path: '/indicacao/:token',
      name: 'indicacao',
      meta: { layout: 'authLayout', auth: 'guest' },
      component: () => import('@/views/auth/AcceptRecommendation.vue')
    },
  ]
