<template>
  <div>
    <!---->
    <app-text-field
      v-if="!$vuetify.breakpoint.mobile"
      v-bind="$attrs"
      v-on="$listeners"
      type="time"
      v-model="time"
    />

    <v-dialog
      v-if="$vuetify.breakpoint.mobile"
      ref="section"
      v-model="menu"
      :return-value.sync="time"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <app-text-field
          v-model="time"
          :label="label"
          append-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="{ ...attrs, ...$attrs }"
          v-on="on"
          :rules="$attrs.rules"
        />
      </template>
      <v-time-picker
        v-if="menu"
        v-model="time"
        ampm-in-title
        flat
        format="24hr"
        scrollable
        @click:minute="setSelectedTime()"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
  data() {
    return {
      time: null,
      menu: false,
    };
  },
  watch: {
    value() {
      this.time = this.value;
    },
  },

  created() {
    this.time = this.value;
  },

  computed: {
    label() {
      if (!this.hideLabel) {
        return this.$attrs.label || "Hora";
      }
    },
    hideLabel() {
      return this.$attrs.hideLabel;
    },

    // retorna se o formato da hora é 24h ou 12h de acordo navegador
  },
  methods: {
    setSelectedTime(selectedTime) {
      // this.time = selectedTime;
      // this.$refs.section.save(time);

      this.$refs.section.save(this.time);

      this.$emit("input", this.time);
    },
  },
};
</script>