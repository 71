<template>
  <v-main class="page">
    <v-container>
      <div class="component">
        <slot></slot>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.page
  background-color: #fff
.component
  margin-top: 5%
  align-items: center
  justify-content: center
  align-content: center
</style>
 