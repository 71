<template>
  <div>
    <div id="code">
      <code>
        <pre v-html="highlightedCode"></pre>
      </code>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },

  computed: {
    dataJson() {
      return this.formatJson(this.value);
    },
    highlightedCode() {
      if (this.value == null) {
        return "";
      }
      return this.highlightCode(this.dataJson);
    },
  },

  methods: {
    formatJson(dataString) {
      if (dataString) {
        var match = dataString.match(/{.*}/);
        if (match) {
          var jsonPart = match[0];

          var logData = JSON.parse(jsonPart);

          var formattedLog = JSON.stringify(logData, null, 2);

          return formattedLog;
        } else {
          return dataString;
        }
      }
    },
    highlightCode(code) {
      const lines = code.split("\n");
      const numberedLines = lines.map(
        (line, index) => `${index + 1}:   ${line}`
      );

      const highlightedCode = numberedLines
        .map((line) => {
          line = line
            .replace(/{/g, '<span style="color: #ff79c6;">{</span>')
            .replace(/}/g, '<span style="color: #ff79c6;">}</span>')
            .replace(/\[/g, '<span style="color: #50fa7b;">[</span>')
            .replace(/]/g, '<span style="color: #50fa7b;">]</span>');

          return line;
        })
        .join("\n");

      return highlightedCode;
    },
  },
};
</script>

<style lang="scss">
#code {
  background-color: #282a36;
  padding: 10px;
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #f8f8f2;
    font-family: courier, monospace;
  }
  code {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #f8f8f2;
    font-family: courier, monospace;
  }
}
</style>
