import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import auth from './plugins/auth'
import broadcast from './plugins/broadcast'
import calc from './plugins/calc'
import format from './plugins/format'
import globalComponents from './plugins/globalComponents'
import hotjar from './plugins/hotjar'
import tracker from './plugins/tracker'
import http from './plugins/http'
import portal from './plugins/portal'
import vueCurrencyInput from './plugins/vueCurrencyInput'
import vueClipboard from './plugins/vueClipboard'
import vueDebounce from './plugins/vueDebounce'
import vuetify from './plugins/vuetify'
import veeValidate from './plugins/veeValidate'
import { VueMaskDirective } from 'v-mask'
import firebase from './plugins/firebase'
import "./assets/style/variables.scss";
import loading from './plugins/loading'
import acl from './plugins/acl'
import admin from './plugins/admin'
import snackbar from './plugins/snackbar'
import customerAlias from './plugins/customerAlias'
import vueFacebookPixel from './plugins/vueFacebookPixel'
import webtonative from './plugins/webtonative'
import doc from './plugins/doc'
import 'material-symbols';
import bugsnag from './plugins/bugsnag'
Vue.directive('mask', VueMaskDirective);


Vue.config.productionTip = false


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

new Vue({
  webtonative,
  vueClipboard,
  bugsnag,
  doc,
  router,
  store,
  auth,
  broadcast,
  calc,
  format,
  globalComponents,
  hotjar,
  tracker,
  http,
  portal,
  vueCurrencyInput,
  vueDebounce,
  vuetify,
  veeValidate,
  firebase,
  loading,
  snackbar,
  acl,
  admin,
  customerAlias,
  vueFacebookPixel,
  render: h => h(App)
}).$mount('#app')
