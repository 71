
import Vue from 'vue'

Vue.component('app-file-field', require('@/components/app/ui/AppFileField.vue').default)
Vue.component('app-text-field', require('@/components/app/ui/AppTextField.vue').default)
Vue.component('app-number-field', require('@/components/app/ui/AppNumberField.vue').default)
Vue.component('app-date-field', require('@/components/app/ui/AppDateField.vue').default)
Vue.component('app-date-picker', require('@/components/app/ui/AppDatePicker.vue').default)
Vue.component('app-time-picker', require('@/components/app/ui/AppTimePicker.vue').default)
Vue.component('app-month-select', require('@/components/app/ui/AppMonthSelect.vue').default)
Vue.component('app-code-field', require('@/components/app/ui/AppCodeField.vue').default)
Vue.component('app-mask-field', require('@/components/app/ui/AppMaskField.vue').default)
Vue.component('app-text-search-field', require('@/components/app/ui/AppTextSearchField.vue').default)
Vue.component('app-select', require('@/components/app/ui/AppSelect.vue').default)
Vue.component('app-cnpj-field', require('@/components/app/ui/AppCnpjField.vue').default)
Vue.component('app-password-field', require('@/components/app/ui/AppPasswordField.vue').default)
Vue.component('app-icon', require('@/components/app/ui/AppIcon.vue').default)
Vue.component('app-cep-field', require('@/components/app/ui/AppCepField.vue').default)

Vue.component('AppCode', require('@/components/app/sections/AppCode.vue').default)
Vue.component('app-title', require('@/components/app/sections/AppTitle.vue').default)
Vue.component('app-alert', require('@/components/app/sections/AppAlert.vue').default)
Vue.component('app-img', require('@/components/app/sections/AppImg.vue').default)
Vue.component('app-transaction-status-chip', require('@/components/app/sections/AppTransactionStatusChip.vue').default)

Vue.component('status-chip', require('@/components/app/sections/StatusChip.vue').default)
Vue.component('app-cpf-field', require('@/components/app/ui/AppCpfField.vue').default)
Vue.component('app-rg-field', require('@/components/app/ui/AppRgField.vue').default)
Vue.component('app-phone-field', require('@/components/app/ui/AppPhoneField.vue').default)
Vue.component('app-cancel-btn', require('@/components/app/ui/AppCancelBtn.vue').default)
Vue.component('app-pagination', require('@/components/app/ui/AppPagination.vue').default)
Vue.component('app-whatsapp-field', require('@/components/app/ui/AppWhatsappField.vue').default)
Vue.component('app-search-field', require('@/components/app/ui/AppSearchField.vue').default)
Vue.component('app-delete-btn', require('@/components/app/ui/AppDeleteBtn.vue').default)

//new
Vue.component('app-btn', require('@/components/app/ui/AppBtn.vue').default)
Vue.component('app-back-btn', require('@/components/app/ui/AppBackBtn.vue').default)
Vue.component('app-confirm-btn', require('@/components/app/ui/AppConfirmBtn.vue').default)



export default (_) => { }