<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Alterar senha </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <app-password-field
                label="Senha atual"
                v-model="form.oldPassword"
              />
            </v-col>
            <v-col cols="12">
              <app-password-field
                label="Nova Senha"
                v-model="form.newPassword"
              />
            </v-col>
            <v-col cols="12">
              <app-password-field
                label="Confirme sua senha"
                v-model="form.newPassword_confirmation"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions style="background-color: #f5f5f5">
        <v-spacer></v-spacer>
        <v-btn @click="store()" class="mr-2" color="primary">Salvar</v-btn>
        <v-btn @click="dialog = false" text>Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  data: () => ({
    dialog: false,
    form: {},
    baseForm: {
      oldPassword: null,
      newPassword: null,
      newPassword_confirmation: null,
    },
  }),
  methods: {
    async open() {
      await this.reset();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async store() {
      this.$loading.start();
      await this.$http
        .store("user/update-password", this.form)
        .then((response) => {
          this.$loading.finish();
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>
