export default [

  { country: 'Afeganistão                      ',            isoCode: 'AF',                 ddiCode: '93'  },
  { country: 'África do Sul                    ',            isoCode: 'ZA',                 ddiCode: '27'  },
  { country: 'Albânia                          ',            isoCode: 'AL',                 ddiCode: '355' },
  { country: 'Alemanha                         ',            isoCode: 'DE',                 ddiCode: '49'  },
  { country: 'Andora                           ',            isoCode: 'AD',                 ddiCode: '376' },
  { country: 'Angola                           ',            isoCode: 'AO',                 ddiCode: '244' },
  { country: 'Anguilla                         ',            isoCode: 'AI',                 ddiCode: '1'   },
  { country: 'Antarctica                       ',            isoCode: 'AQ',                 ddiCode: '672' },
  { country: 'Antígua e Barbuda                ',            isoCode: 'AG',                 ddiCode: '1'   },
  { country: 'Argentina                        ',            isoCode: 'AR',                 ddiCode: '54'  },
  { country: 'Argélia                          ',            isoCode: 'DZ',                 ddiCode: '213' },
  { country: 'Armênia                          ',            isoCode: 'AM',                 ddiCode: '374' },
  { country: 'Aruba                            ',            isoCode: 'AW',                 ddiCode: '297' },
  { country: 'Arábia Saudita                   ',            isoCode: 'SA',                 ddiCode: '966' },
  { country: 'Austrália                        ',            isoCode: 'AU',                 ddiCode: '61'  },
  { country: 'Áustria                          ',            isoCode: 'AT',                 ddiCode: '43'  },
  { country: 'Azerbaijão                       ',            isoCode: 'AZ',                 ddiCode: '994' },
  { country: 'Bahamas                          ',            isoCode: 'BS',                 ddiCode: '1'   },
  { country: 'Bahrein                          ',            isoCode: 'BH',                 ddiCode: '973' },
  { country: 'Bangladesh                       ',            isoCode: 'BD',                 ddiCode: '880' },
  { country: 'Barbados                         ',            isoCode: 'BB',                 ddiCode: '1'   },
  { country: 'Bélgica                          ',            isoCode: 'BE',                 ddiCode: '32'  },
  { country: 'Belize                           ',            isoCode: 'BZ',                 ddiCode: '501' },
  { country: 'Benim                            ',            isoCode: 'BJ',                 ddiCode: '229' },
  { country: 'Bermuda                          ',            isoCode: 'BM',                 ddiCode: '1'   },
  { country: 'Butão                            ',            isoCode: 'BT',                 ddiCode: '975' },
  { country: 'Bielorrússia                     ',            isoCode: 'BY',                 ddiCode: '375' },
  { country: 'Bolívia                          ',            isoCode: 'BO',                 ddiCode: '591' },
  { country: 'Bósnia e Herzegovina             ',            isoCode: 'BA',                 ddiCode: '387' },
  { country: 'Botswana                         ',            isoCode: 'BW',                 ddiCode: '267' },
  { country: 'Brasil                           ',            isoCode: 'BR',                 ddiCode: '55'  },
  { country: 'Brunei                           ',            isoCode: 'BN',                 ddiCode: '673' },
  { country: 'Bulgária                         ',            isoCode: 'BG',                 ddiCode: '359' },
  { country: 'Burkina Faso                     ',            isoCode: 'BF',                 ddiCode: '226' },
  { country: 'Myanmar (Burma)                  ',            isoCode: 'MM',                 ddiCode: '95'  },
  { country: 'Burundi                          ',            isoCode: 'BI',                 ddiCode: '257' },
  { country: 'Camarões                         ',            isoCode: 'CM',                 ddiCode: '237' },
  { country: 'Cabo Verde                       ',            isoCode: 'CV',                 ddiCode: '238' },
  { country: 'Camboja                          ',            isoCode: 'KH',                 ddiCode: '855' },
  { country: 'Canadá                           ',            isoCode: 'CA',                 ddiCode: '1'   },
  { country: 'Catar                            ',            isoCode: 'QA',                 ddiCode: '974' },
  { country: 'Chad                             ',            isoCode: 'TD',                 ddiCode: '235' },
  { country: 'Chile                            ',            isoCode: 'CL',                 ddiCode: '56'  },
  { country: 'China                            ',            isoCode: 'CN',                 ddiCode: '86'  },
  { country: 'Chipre                           ',            isoCode: 'CY',                 ddiCode: '357' },
  { country: 'Christmas Island                 ',            isoCode: 'CX',                 ddiCode: '61'  },
  { country: 'Cingapura                        ',            isoCode: 'SG',                 ddiCode: '65'  },
  { country: 'Colômbia                         ',            isoCode: 'CO',                 ddiCode: '57'  },
  { country: 'Comores                          ',            isoCode: 'KM',                 ddiCode: '269' },
  { country: 'Coreia do Norte                  ',            isoCode: 'KP',                 ddiCode: '850' },
  { country: 'Coreia do Sul                    ',            isoCode: 'KR',                 ddiCode: '82'  },
  { country: 'Costa do Marfim                  ',            isoCode: 'CI',                 ddiCode: '225' },
  { country: 'Costa Rica                       ',            isoCode: 'CR',                 ddiCode: '506' },
  { country: 'Croácia                          ',            isoCode: 'HR',                 ddiCode: '385' },
  { country: 'Cuba                             ',            isoCode: 'CU',                 ddiCode: '53'  },
  { country: 'Dinamarca                        ',            isoCode: 'DK',                 ddiCode: '45'  },
  { country: 'Djibouti                         ',            isoCode: 'DJ',                 ddiCode: '253' },
  { country: 'Dominica                         ',            isoCode: 'DM',                 ddiCode: '1'   },
  { country: 'Egito                            ',            isoCode: 'EG',                 ddiCode: '20'  },
  { country: 'El Salvador                      ',            isoCode: 'SV',                 ddiCode: '503' },
  { country: 'Emirados Árabes Unidos           ',            isoCode: 'AE',                 ddiCode: '971' },
  { country: 'Equador                          ',            isoCode: 'EC',                 ddiCode: '593' },
  { country: 'Eritreia                         ',            isoCode: 'ER',                 ddiCode: '291' },
  { country: 'Estónia                          ',            isoCode: 'EE',                 ddiCode: '372' },
  { country: 'Espanha                          ',            isoCode: 'ES',                 ddiCode: '34'  },
  { country: 'Etiópia                          ',            isoCode: 'ET',                 ddiCode: '251' },
  { country: 'Fiji                             ',            isoCode: 'FJ',                 ddiCode: '679' },
  { country: 'Finlândia                        ',            isoCode: 'FI',                 ddiCode: '358' },
  { country: 'França                           ',            isoCode: 'FR',                 ddiCode: '33'  },
  { country: 'Gabão                            ',            isoCode: 'GA',                 ddiCode: '241' },
  { country: 'Gâmbia                           ',            isoCode: 'GM',                 ddiCode: '220' },
  { country: 'Geórgia                          ',            isoCode: 'GE',                 ddiCode: '995' },
  { country: 'Gana                             ',            isoCode: 'GH',                 ddiCode: '233' },
  { country: 'Gibraltar                        ',            isoCode: 'GI',                 ddiCode: '350' },
  { country: 'Grécia                           ',            isoCode: 'GR',                 ddiCode: '30'  },
  { country: 'Groelândia                       ',            isoCode: 'GL',                 ddiCode: '299' },
  { country: 'Granada                          ',            isoCode: 'GD',                 ddiCode: '1'   },
  { country: 'Guam                             ',            isoCode: 'GU',                 ddiCode: '1'   },
  { country: 'Guatemala                        ',            isoCode: 'GT',                 ddiCode: '502' },
  { country: 'Guiana                           ',            isoCode: 'GY',                 ddiCode: '592' },
  { country: 'Guiné                            ',            isoCode: 'GN',                 ddiCode: '224' },
  { country: 'Guiné Equatorial                 ',            isoCode: 'GQ',                 ddiCode: '240' },
  { country: 'Guiné-Bissau                     ',            isoCode: 'GW',                 ddiCode: '245' },
  { country: 'Haiti                            ',            isoCode: 'HT',                 ddiCode: '509' },
  { country: 'Honduras                         ',            isoCode: 'HN',                 ddiCode: '504' },
  { country: 'Hong Kong                        ',            isoCode: 'HK',                 ddiCode: '852' },
  { country: 'Hungria                          ',            isoCode: 'HU',                 ddiCode: '36'  },
  { country: 'Islândia                         ',            isoCode: 'IS',                 ddiCode: '354' },
  { country: 'Ilhas Cayman                     ',            isoCode: 'KY',                 ddiCode: '1'   },
  { country: 'Ilhas Cocos (Keeling)            ',            isoCode: 'CC',                 ddiCode: '61'  },
  { country: 'Ilha de Man                      ',            isoCode: 'IM',                 ddiCode: '44'  },
  { country: 'Ilhas Cook                       ',            isoCode: 'CK',                 ddiCode: '682' },
  { country: 'Ilhas Falkland (Malvinas)        ',            isoCode: 'FK',                 ddiCode: '500' },
  { country: 'Ilhas Feroé                      ',            isoCode: 'FO',                 ddiCode: '298' },
  { country: 'Ilhas Mariana do Norte           ',            isoCode: 'MP',                 ddiCode: '1'   },
  { country: 'Ilhas Marshall                   ',            isoCode: 'MH',                 ddiCode: '692' },
  { country: 'Ilhas Pitcairn                   ',            isoCode: 'PN',                 ddiCode: '870' },
  { country: 'Ilhas Salomão                    ',            isoCode: 'SB',                 ddiCode: '677' },
  { country: 'Ilhas Turcas e Caicos            ',            isoCode: 'TC',                 ddiCode: '1'   },
  { country: 'Ilhas Virgens Americanas         ',            isoCode: 'VI',                 ddiCode: '1'   },
  { country: 'Ilhas Virgens Britânicas         ',            isoCode: 'VG',                 ddiCode: '1'   },
  { country: 'India                            ',            isoCode: 'IN',                 ddiCode: '91'  },
  { country: 'Indonésia                        ',            isoCode: 'ID',                 ddiCode: '62'  },
  { country: 'Inglaterra (Reino Unido)         ',            isoCode: 'GB',                 ddiCode: '44'  },
  { country: 'Irã                              ',            isoCode: 'IR',                 ddiCode: '98'  },
  { country: 'Iraque                           ',            isoCode: 'IQ',                 ddiCode: '964' },
  { country: 'Irlanda                          ',            isoCode: 'IE',                 ddiCode: '353' },
  { country: 'Israel                           ',            isoCode: 'IL',                 ddiCode: '972' },
  { country: 'Itália                           ',            isoCode: 'IT',                 ddiCode: '39'  },
  { country: 'Jamaica                          ',            isoCode: 'JM',                 ddiCode: '1'   },
  { country: 'Japão                            ',            isoCode: 'JP',                 ddiCode: '81'  },
  { country: 'Jordânia                         ',            isoCode: 'JO',                 ddiCode: '962' },
  { country: 'Cazaquistão                      ',            isoCode: 'KZ',                 ddiCode: '7'   },
  { country: 'Quénia (Kenya)                   ',            isoCode: 'KE',                 ddiCode: '254' },
  { country: 'Kiribati                         ',            isoCode: 'KI',                 ddiCode: '686' },
  { country: 'Kosovo                           ',            isoCode: 'XK',                 ddiCode: '381' },
  { country: 'Kuwait                           ',            isoCode: 'KW',                 ddiCode: '965' },
  { country: 'Quirguistão                      ',            isoCode: 'KG',                 ddiCode: '996' },
  { country: 'Laos                             ',            isoCode: 'LA',                 ddiCode: '856' },
  { country: 'Letônia                          ',            isoCode: 'LV',                 ddiCode: '371' },
  { country: 'Líbano                           ',            isoCode: 'LB',                 ddiCode: '961' },
  { country: 'Lesoto                           ',            isoCode: 'LS',                 ddiCode: '266' },
  { country: 'Libéria                          ',            isoCode: 'LR',                 ddiCode: '231' },
  { country: 'Líbia                            ',            isoCode: 'LY',                 ddiCode: '218' },
  { country: 'Liechtenstein                    ',            isoCode: 'LI',                 ddiCode: '423' },
  { country: 'Lituânia                         ',            isoCode: 'LT',                 ddiCode: '370' },
  { country: 'Luxemburgo                       ',            isoCode: 'LU',                 ddiCode: '352' },
  { country: 'Macau                            ',            isoCode: 'MO',                 ddiCode: '853' },
  { country: 'Macedónia                        ',            isoCode: 'MK',                 ddiCode: '389' },
  { country: 'Madagáscar                       ',            isoCode: 'MG',                 ddiCode: '261' },
  { country: 'Malawi                           ',            isoCode: 'MW',                 ddiCode: '265' },
  { country: 'Malásia                          ',            isoCode: 'MY',                 ddiCode: '60'  },
  { country: 'Maldivas                         ',            isoCode: 'MV',                 ddiCode: '960' },
  { country: 'Mali                             ',            isoCode: 'ML',                 ddiCode: '223' },
  { country: 'Malta                            ',            isoCode: 'MT',                 ddiCode: '356' },
  { country: 'Mauritânia                       ',            isoCode: 'MR',                 ddiCode: '222' },
  { country: 'Maurícia                         ',            isoCode: 'MU',                 ddiCode: '230' },
  { country: 'Mayotte                          ',            isoCode: 'YT',                 ddiCode: '262' },
  { country: 'México                           ',            isoCode: 'MX',                 ddiCode: '52'  },
  { country: 'Micronésia                       ',            isoCode: 'FM',                 ddiCode: '691' },
  { country: 'Moldávia                         ',            isoCode: 'MD',                 ddiCode: '373' },
  { country: 'Monaco                           ',            isoCode: 'MC',                 ddiCode: '377' },
  { country: 'Mongólia                         ',            isoCode: 'MN',                 ddiCode: '976' },
  { country: 'Montenegro                       ',            isoCode: 'ME',                 ddiCode: '382' },
  { country: 'Montserrat                       ',            isoCode: 'MS',                 ddiCode: '1'   },
  { country: 'Marrocos                         ',            isoCode: 'MA',                 ddiCode: '212' },
  { country: 'Moçambique                       ',            isoCode: 'MZ',                 ddiCode: '258' },
  { country: 'Namíbia                          ',            isoCode: 'NA',                 ddiCode: '264' },
  { country: 'Nauru                            ',            isoCode: 'NR',                 ddiCode: '674' },
  { country: 'Nepal                            ',            isoCode: 'NP',                 ddiCode: '977' },
  { country: 'Netherlands Antilles             ',            isoCode: 'AN',                 ddiCode: '599' },
  { country: 'Nova Caledônia                   ',            isoCode: 'NC',                 ddiCode: '687' },
  { country: 'Nova Zelândia                    ',            isoCode: 'NZ',                 ddiCode: '64'  },
  { country: 'Nicaragua                        ',            isoCode: 'NI',                 ddiCode: '505' },
  { country: 'Níger                            ',            isoCode: 'NE',                 ddiCode: '227' },
  { country: 'Nigéria                          ',            isoCode: 'NG',                 ddiCode: '234' },
  { country: 'Niue                             ',            isoCode: 'NU',                 ddiCode: '683' },
  { country: 'Noruega                          ',            isoCode: 'NO',                 ddiCode: '47'  },
  { country: 'Omã                              ',            isoCode: 'OM',                 ddiCode: '968' },
  { country: 'Países Baixos                    ',            isoCode: 'NL',                 ddiCode: '31'  },
  { country: 'Paquistão                        ',            isoCode: 'PK',                 ddiCode: '92'  },
  { country: 'Palau                            ',            isoCode: 'PW',                 ddiCode: '680' },
  { country: 'Panamá                           ',            isoCode: 'PA',                 ddiCode: '507' },
  { country: 'Papua-Nova Guiné                 ',            isoCode: 'PG',                 ddiCode: '675' },
  { country: 'Paraguai                         ',            isoCode: 'PY',                 ddiCode: '595' },
  { country: 'Peru                             ',            isoCode: 'PE',                 ddiCode: '51'  },
  { country: 'Filipinas                        ',            isoCode: 'PH',                 ddiCode: '63'  },
  { country: 'Polónia                          ',            isoCode: 'PL',                 ddiCode: '48'  },
  { country: 'Polinésia Francesa               ',            isoCode: 'PF',                 ddiCode: '689' },
  { country: 'Portugal                         ',            isoCode: 'PT',                 ddiCode: '351' },
  { country: 'Porto Rico                       ',            isoCode: 'PR',                 ddiCode: '1'   },
  { country: 'República do Congo               ',            isoCode: 'CG',                 ddiCode: '242' },
  { country: 'República Democrática do Congo   ',            isoCode: 'CD',                 ddiCode: '243' },
  { country: 'República Centro-Africana        ',            isoCode: 'CF',                 ddiCode: '236' },
  { country: 'República Checa                  ',            isoCode: 'CZ',                 ddiCode: '420' },
  { country: 'República Dominicana             ',            isoCode: 'DO',                 ddiCode: '1'   },
  { country: 'Roménia                          ',            isoCode: 'RO',                 ddiCode: '40'  },
  { country: 'Ruanda                           ',            isoCode: 'RW',                 ddiCode: '250' },
  { country: 'Rússia                           ',            isoCode: 'RU',                 ddiCode: '7'   },
  { country: 'Saint Barthelemy                 ',            isoCode: 'BL',                 ddiCode: '590' },
  { country: 'Samoa                            ',            isoCode: 'WS',                 ddiCode: '685' },
  { country: 'Samoa Americana                  ',            isoCode: 'AS',                 ddiCode: '1'   },
  { country: 'San Marino                       ',            isoCode: 'SM',                 ddiCode: '378' },
  { country: 'Sao Tome e Principe              ',            isoCode: 'ST',                 ddiCode: '239' },
  { country: 'Senegal                          ',            isoCode: 'SN',                 ddiCode: '221' },
  { country: 'Serbia                           ',            isoCode: 'RS',                 ddiCode: '381' },
  { country: 'Serra Leoa                       ',            isoCode: 'SL',                 ddiCode: '232' },
  { country: 'Seychelles                       ',            isoCode: 'SC',                 ddiCode: '248' },
  { country: 'Slovakia                         ',            isoCode: 'SK',                 ddiCode: '421' },
  { country: 'Slovenia                         ',            isoCode: 'SI',                 ddiCode: '386' },
  { country: 'Somalia                          ',            isoCode: 'SO',                 ddiCode: '252' },
  { country: 'Sri Lanka                        ',            isoCode: 'LK',                 ddiCode: '94'  },
  { country: 'Saint Helena                     ',            isoCode: 'SH',                 ddiCode: '290' },
  { country: 'Saint Kitts and Nevis            ',            isoCode: 'KN',                 ddiCode: '1'   },
  { country: 'Saint Lucia                      ',            isoCode: 'LC',                 ddiCode: '1'   },
  { country: 'Saint Martin                     ',            isoCode: 'MF',                 ddiCode: '1'   },
  { country: 'Saint Pierre and Miquelon        ',            isoCode: 'PM',                 ddiCode: '508' },
  { country: 'Saint Vincent and the Grenadines ',            isoCode: 'VC',                 ddiCode: '1'   },
  { country: 'Sudão                            ',            isoCode: 'SD',                 ddiCode: '249' },
  { country: 'Suriname                         ',            isoCode: 'SR',                 ddiCode: '597' },
  { country: 'Suazilândia                      ',            isoCode: 'SZ',                 ddiCode: '268' },
  { country: 'Suécia                           ',            isoCode: 'SE',                 ddiCode: '46'  },
  { country: 'Suiça                            ',            isoCode: 'CH',                 ddiCode: '41'  },
  { country: 'Syria                            ',            isoCode: 'SY',                 ddiCode: '963' },
  { country: 'Taiwan                           ',            isoCode: 'TW',                 ddiCode: '886' },
  { country: 'Tajiquistão                      ',            isoCode: 'TJ',                 ddiCode: '992' },
  { country: 'Tanzânia                         ',            isoCode: 'TZ',                 ddiCode: '255' },
  { country: 'Timor-Leste                      ',            isoCode: 'TL',                 ddiCode: '670' },
  { country: 'Tailândia                        ',            isoCode: 'TH',                 ddiCode: '66'  },
  { country: 'Togo                             ',            isoCode: 'TG',                 ddiCode: '228' },
  { country: 'Tokelau                          ',            isoCode: 'TK',                 ddiCode: '690' },
  { country: 'Tonga                            ',            isoCode: 'TO',                 ddiCode: '676' },
  { country: 'Trinidad e Tobago                ',            isoCode: 'TT',                 ddiCode: '1'   },
  { country: 'Tunísia                          ',            isoCode: 'TN',                 ddiCode: '216' },
  { country: 'Turquemenistão                   ',            isoCode: 'TM',                 ddiCode: '993' },
  { country: 'Turquia                          ',            isoCode: 'TR',                 ddiCode: '90'  },
  { country: 'Tuvalu                           ',            isoCode: 'TV',                 ddiCode: '688' },
  { country: 'Uganda                           ',            isoCode: 'UG',                 ddiCode: '256' },
  { country: 'Ucrânia                          ',            isoCode: 'UA',                 ddiCode: '380' },
  { country: 'Uruguai                          ',            isoCode: 'UY',                 ddiCode: '598' },
  { country: 'Estados Unidos (EUA)             ',            isoCode: 'US',                 ddiCode: '1'   },
  { country: 'Uzbequistão                      ',            isoCode: 'UZ',                 ddiCode: '998' },
  { country: 'Vanuatu                          ',            isoCode: 'VU',                 ddiCode: '678' },
  { country: 'Vaticano                         ',            isoCode: 'VA',                 ddiCode: '39'  },
  { country: 'Venezuela                        ',            isoCode: 'VE',                 ddiCode: '58'  },
  { country: 'Vietnã (Vietname)                ',            isoCode: 'VN',                 ddiCode: '84'  },
  { country: 'Wallis e Futuna                  ',            isoCode: 'WF',                 ddiCode: '681' },
  { country: 'Iémen (Iémen, Yemen)             ',            isoCode: 'YE',                 ddiCode: '967' },
  { country: 'Zimbabwe(Zimbabué)               ',            isoCode: 'ZW',                 ddiCode: '263' },
  { country: 'Zâmbia                           ',            isoCode: 'ZM',                 ddiCode: '260' },
]