
<template>
  <div class="slot">
    <div :flat="$vuetify.breakpoint.mobile">
      <v-main>
        <v-container>
          <slot />
        </v-container>
      </v-main>
    </div>
  </div>
  <!-- <v-main style="height: 100%">
    <v-container class="pa-0 slot">
      <slot />
    </v-container>
  </v-main> -->
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
// *
//   background: #9e74da

@media (min-width: 960px)
  .slot
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    background: #eeeeee

@media (max-width: 960px)
  .slot
    display: flex
    justify-content: center
    align-items: center
    height: 100%
</style>