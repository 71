<template>
  <v-text-field
    v-bind="$attrs"
    :hide-details="hideDetails"
    v-on="$listeners"
    :dense="dense"
    autocomplete="off"
  >
    <template v-for="(index, scopedSlot) in $scopedSlots" #[scopedSlot]="data">
      <slot :name="scopedSlotName" v-bind="data" />
    </template>
    <template v-for="(index, slot) in $slots" #[slot]>
      <slot :name="slot" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    hideDetails: {
      default: "auto",
    },
    dense: {
      default: true,
    },
  },
};
</script>
