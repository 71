<template>
  <v-system-bar
    height="30"
    class="d-flex justify-center"
    :color="applicationStatus.color"
    v-if="visible"
    dark
    app
  >
    <app-icon left>{{ applicationStatus.icon }}</app-icon>

    {{ applicationStatus.text }}
  </v-system-bar>
</template>
<script>
export default {
  data: () => ({
    applicationStatus: null,
    visible: false,
  }),

  computed: {
    appIsOffline() {
      return this.$store.getters["app/appIsOffline"];
    },
  },

  watch: {
    appIsOffline: {
      deep: true,
      handler(newValue, oldValue) {
        this.setAlertType();
      },
    },
  },

  methods: {
    setAlertType() {
      if (this.appIsOffline) {
        this.applicationStatus = {
          icon: "wifi_off",
          color: "error",
          text: "Você está offline, Verifique sua conexão com a internet",
        };

        this.visible = true;
      }
      if (!this.appIsOffline) {
        this.applicationStatus = {
          icon: "wifi",
          color: "success",
          text: "Conectando...",
        };

        setTimeout(() => {
          this.visible = false;
        }, 5000);
      }
    },
  },
};
</script>
 