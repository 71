
import Doc from './../utils/doc.js'

import Vue from 'vue';

Vue.use({
  install(Vue) {
    let doc = new Doc();
    Vue.prototype.$doc = doc
    Vue.$doc = doc;
  }
})

export default (_) => { }