<template>
  <div class="d-flex justify-center align-center" style="max-width: 100%">
    <div>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <div class="d-flex align-center">
            <v-btn icon color="primary" @click.stop="previous()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <h3 class="d-inline mx-4" v-on="on">{{ displayDate }}</h3>

            <v-btn icon color="primary" @click.stop="next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </template>
        <v-date-picker
          locale="pt-Br"
          v-model="formatedDate"
          no-title
          scrollable
          @input="$refs.menu.save(date)"
          :type="'month'"
        >
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
import {
  format,
  parse,
  isThisYear,
  addMonths,
  subMonths,
  startOfMonth,
  lastDayOfMonth,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export default {
  props: {
    //value: {},
  },

  created() {
    this.setToday();
  },

  data: () => ({
    date: null,

    menu: null,
  }),

  computed: {

    displayDate() {
      return format(this.date, "MMMM yyyy", { locale: ptBR });
    },

    formatedDate: {
      get: function () {
        return this.formatMonthDate(this.date)
      },
      set: function (newValue) {
        this.date = this.parseMonthDate(newValue)
      },
    },
  },

  watch: {
    date() {
      this.input();
    },
  },

  methods: {
    setToday() {
      this.date = new Date();
    },

    parseMonthDate(date) {
      return parse(date, 'yyyy-MM', new Date())
    },

    formatMonthDate(date) {
      return format(date, 'yyyy-MM')
    },

    next() {
      this.date = addMonths(this.date, 1);
    },

    previous() {
      this.date = subMonths(this.date, 1);
    },

    input() {
      let start = format(startOfMonth(this.date), "yyyy-MM-dd");
      let end = format(lastDayOfMonth(this.date), "yyyy-MM-dd");

      this.$emit("input", { start, end });
    },
  },
};
</script>
