import mixpanel from "mixpanel-browser";

class Tracker {
  constructor(options) {
    this.user = null;

    this.pixelInitiated = false;
    this.mixpanelInitiated = false;
    this.gaInitiated = false;

    this.options = options;
  }

  init() {
    this.initPixel();
    this.initMixpanel();
    this.initGa();
  }

  initPixel() {
    if (!this.options.pixel) return;
    if (!this.options.pixel.key) return;

    this.fbq = fbq;

    this.fbq("init", this.options.pixel.key);

    this.pixelInitiated = true;
  }

  initMixpanel() {
    if (!this.options.mixpanel) return;
    if (!this.options.mixpanel.key) return;

    this.mixpanel = mixpanel;

    this.mixpanel.init(
      this.options.mixpanel.key,
      this.options.mixpanel.options
    );

    this.mixpanelInitiated = true;
  }

  initGa() {
    this.ga = gtag;
    
    this.ga('config', 'G-B425K961PT', {'debug_mode': false}); // todo get from .env

    this.gaInitiated = true;
  }

  setUser(user) {
    this.user = user;

    if (this.mixpanelInitiated) {
      this.mixpanel.identify(this.user.id);
    }

    if (this.gaInitiated) {
      this.ga("set", { user_id: this.user.id });
    }
  }

  track(event, params = null) {
    if (this.pixelInitiated) {
      this.fbq("track", event, params);
    }

    if (this.mixpanelInitiated) {
      this.mixpanel.track(event, params);
    }
  }

  signUp(params) {
    if (this.pixelInitiated) {
      this.fbq('track', 'StartTrial', params);
    }

    if (this.mixpanelInitiated) {
      this.mixpanel.track('StartTrial', params);
    }

    if (this.gaInitiated) {
      this.ga("event", "sign_up", params);
    }
  }

  login(params) {
    if (this.gaInitiated) {
      this.ga("event", "login", params);
    }
  }
}

export default Tracker;
