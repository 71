import subscriptionMiddleware from '@/router/middleware/subscriptionMiddleware';

export default [
  {
    path: "/configuracoes",
    name: "settingsList",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Configurações", icon: "settings", showLink: true, },
    component: () => import("@/views/configuracoes/index.vue"),
  },






  // ///////////////////////////

  {
    path: "/configuracoes/clinica",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Configurações da Clínica", icon: "mdi-domain", showLinkSettings: true },
    component: () => import("@/views/configuracoes/Clinica.vue"),
  },


  {
    path: "/configuracoes/agenda",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Configurações da Agenda", icon: "mdi-calendar", showLinkSettings: true },
    component: () => import("@/views/configuracoes/Agenda.vue"),
  },
  {
    path: "/configuracoes/anamneses",
    name: "anamneses", meta: {
      middleware: [subscriptionMiddleware], layout: "appLayout", name: "Modelos de Anamnese", icon: "mdi-forum-outline", showLinkSettings: true

    },
    component: () => import("@/views/configuracoes/Anamnese.vue"),
  },



  {
    path: "/configuracoes/usuarios",
    name: "usuarios",
    meta: { layout: "appLayout", name: "Usuários", icon: "mdi-account-multiple-outline", showLinkSettings: true },
    component: () => import("@/views/configuracoes/Usuarios.vue"),
  },


  {
    path: "/configuracoes/salas",
    meta: {
      middleware: [subscriptionMiddleware], layout: "appLayout", name: "Configurações de Salas", icon: "mdi-chair-rolling", showLinkSettings: true,
      showFor: { clinic: true, owner: true }
    },
    component: () => import("@/views/configuracoes/Salas.vue"),
  },

  {
    path: "/configuracoes/aplicativo",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Dados do Aplicativo" },
    component: () => import("@/views/configuracoes/Aplicativo.vue"),
  },
  {
    path: "/configuracoes/configurar-mensagem",
    name: "configurar-mensagem",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Configurações de Mensagens", icon: "mdi-phone-message-outline", showLinkSettings: true },
    component: () => import("@/views/configuracoes/Mensagens.vue"),
  },


  {
    path: "/configuracoes/anamnese/novo",
    name: "nova-anamnese",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Novo Modelo de Anamnese",
    },
    component: () =>
      import(
        "@/components/anamnese/forms/AnamneseForm.vue"
      ),
  },
  {
    path: "/configuracoes/anamnese/:id",
    name: "anamnese",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Editar Modelo de Anamnese",
    },
    component: () =>
      import(
        "@/components/anamnese/forms/AnamneseForm.vue"
      ),
  },
  {
    path: "/configuracoes/prontuario",
    name: "prontuario",
    meta: {
      middleware: [subscriptionMiddleware], layout: "appLayout", name: "Modelos de Prontuario",
      showLinkSettings: true, icon: "mdi-clipboard-text-outline",
    },
    component: () => import("@/views/configuracoes/Prontuario.vue"),
  },

  {
    path: "/configuracoes/prontuario/novo",
    name: "NewProfessionalRecordForm",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Novo Modelo de Prontuario",
    },
    component: () =>
      import(
        "@/components/professional-record/forms/ProfessionalRecordForm.vue"
      ),
  },
  {
    path: "/configuracoes/prontuario/:id",
    name: "updateProfessionalRecordForm",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Editar Modelo de Prontuario",
    },
    component: () =>
      import(
        "@/components/professional-record/forms/ProfessionalRecordForm.vue"
      ),
  },


  {
    path: "/configuracoes/documentos",
    name: "Documentos",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Modelos de Documentos", icon: "mdi-file-document-outline", showLinkSettings: true },
    component: () => import("@/views/configuracoes/documentos"),
  },
  {
    path: "/configuracoes/documentos/novo",
    name: "novo-documento",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Novo Modelo de Documento" },
    component: () => import("@/views/configuracoes/documentos/form.vue"),
  },
  {
    path: "/configuracoes/documentos/:id",
    name: "documento",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Editar Modelo de Documento" },
    component: () => import("@/views/configuracoes/documentos/form.vue"),
  },


  {
    path: '/configuracoes/armazenamento',
    meta: { middleware: [subscriptionMiddleware], layout: 'appLayout', name: 'Armazenamento', icon: "mdi-folder-cog-outline", showLinkSettings: true },
    component: () => import('@/views/configuracoes/Armazenamento.vue')
  },

  {
    path: "/configuracoes/grupos",
    name: "Grupos",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Configurações de Grupos", icon: "mdi-account-group-outline", showLinkSettings: true },
    component: () => import("@/views/configuracoes/Grupos.vue"),
  },

  {
    path: "/configuracoes/minha-conta",
    name: "Minha Conta",
    meta: { middleware: [subscriptionMiddleware], layout: "appLayout", name: "Minha Conta", icon: "mdi-account-circle", showLinkSettings: true },
    component: () => import("@/views/configuracoes/MinhaConta.vue"),
  },

  {
    path: "/configuracoes/assinatura",
    name: "configuracoes-assinatura",
    meta: { layout: "appLayout", name: "Minha Assinatura", icon: "mdi-cash-usd-outline", showLinkSettings: true },
    component: () => import("@/views/configuracoes/Assinatura.vue"),
  },
  {
    path: "/configuracoes/google-agenda",
    name: "AuthGoogleCalendar",
    meta: { layout: "appLayout", name: "Google Calendário" },
    component: () => import("@/views/auth/AuthGoogleCalendar.vue"),
  },
]







