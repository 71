<template>
  <app-text-field
    v-bind="$attrs"
    v-model="maskedVal"
    hide-details="auto"
    v-on="$listeners"
    dense
    v-maska="mask"
    @maska="handleMaska($event)"
  >
    <template v-for="(index, scopedSlot) in $scopedSlots" #[scopedSlot]="data">
      <slot :name="scopedSlotName" v-bind="data" />
    </template>
    <template v-for="(index, slot) in $slots" #[slot]>
      <slot :name="slot" />
    </template>
  </app-text-field>
</template>

<script>

import { mask, maska } from 'maska'

export default {

  directives: { maska },

  props : {
    value : {},
    mask : {
      default : null
    }
  },
  
  data: () => ({
    maskedVal : null,
  }),
  
  created() {
    this.setValue(this.value)
  },

  watch : {
    value(val) {
      this.setValue(val)
    }
  },

  methods : {
    setValue(val) {
      if (val) {
        this.maskedVal = mask(val, this.mask)
      }
    },

    handleMaska(event) {
      this.$emit('input', event.target.dataset.maskRawValue)
    }

  }
 };
</script>