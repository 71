
import Bugsnag from './../utils/bugsnag'

import Vue from 'vue';

Vue.use({
  install(Vue) {

    let bugsnag = new Bugsnag(
      {
        apiKey: process.env.VUE_APP_BUGSNAG_KEY,
      }
    )

    Vue.prototype.$bugsnag = bugsnag
    Vue.$bugsnag = bugsnag;
  }
})

export default (_) => { }