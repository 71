import Vue from 'vue'
import BugsnagApi from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

class Bugsnag {

  constructor(options) {


    if (this.isProduction()) {
      this.init(options)
    }
  }

  isProduction() {
    return process.env.NODE == 'production'
  }

  init(options) {

    BugsnagApi.start({
      apiKey: options.apiKey,
      plugins: [new BugsnagPluginVue()],
    })

    const bugsnagVue = BugsnagApi.getPlugin('vue')

    bugsnagVue.installVueErrorHandler(Vue)

  }

  setUser(id, email, name) {

    if (this.isProduction()) {
      BugsnagApi.setUser(id, email, name)
    }
  }

}

export default Bugsnag 