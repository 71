<template>
  <div style="height:100%">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .container {
    background-color: #202124;
  }
</style>