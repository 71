<template>
  <div>
    <v-btn
      v-if="type == 'button'"
      :outlined="!confirmState"
      color="error"
      class="mr-auto"
      rounded
      @click.stop="handleCancel()"
    >
      <v-icon v-if="!confirmState" dark color="error">mdi-delete </v-icon>
      {{ text }}
    </v-btn>

    <template v-if="type == 'icon'">
      <v-menu absolute :value="confirmState" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="handleCancel()"
            v-bind="attrs"
            v-on="on"
            text
            :disabled="confirmState"
            icon
            color="error"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>

        <v-list dense>
          <v-list-item @click.stop="handleCancel()">
            <v-list-item-title> Tem certeza ?</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-if="type == 'list-item'">
      <v-list-item @click.stop="handleCancel()">
        <v-list-item-action>
          <v-icon color="error" v-text="icon" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title v-text="text" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: "button",
    },
  },

  data: () => ({
    confirmState: false,
  }),

  computed: {
    text() {
      return this.confirmState ? "Tem certeza ?" : "Apagar";
    },
    icon() {
      return this.confirmState ? "mdi-delete-alert" : "mdi-delete";
    },
  },

  watch: {
    confirmState(val) {
      if (val == true) {
        setTimeout(() => (this.confirmState = false), 2000);
      }
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmState) {
        this.$emit("click");
        this.reset();
      } else {
        this.confirmState = true;
      }
    },

    reset() {
      this.confirmState = false;
    },
  },
};
</script>

<style>
</style>