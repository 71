<template>
  <div>
    <v-bottom-sheet v-if="isMobile" attach persistent v-model="visible">
      <v-sheet height="300px" class="text-center">
        <span style="font-size: 70px">🎉</span>
        <div class="py-3">Ei, tem uma nova versão disponível!</div>
        <v-row>
          <v-col cols="12">
            <v-btn color="primary" @click.stop="refreshApp()">
              Atualizar agora!
            </v-btn>
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-btn text color="primary" @click="visible = false">
              Atualizar depois
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog v-if="!isMobile" v-model="visible" persistent width="400">
      <v-card>
        <v-card-title class="pt-15 pb-8 d-flex flex-column text-center">
          <span style="font-size: 50px">🎉</span>
        </v-card-title>
        <v-card-text class="d-flex flex-column align-center px-4 py-4">
          <h2 class="pb-4 text-center">Ei, tem uma nova versão disponível!</h2>
          <span class="text-center"
            >Clique no botão abaixo e mantenha o seu Psicoplanner sempre
            atualizado, é rápido!</span
          >
        </v-card-text>

        <v-card-actions class="d-flex justify-center pa-6">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-btn block color="primary" @click.stop="refreshApp()">
                Atualizar agora!
              </v-btn>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-btn block text color="primary" @click="visible = false">
                Atualizar depois
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    visible: false,
    refreshing: null,
    registration: null,
    service: null,
  }),

  created() {
    document.addEventListener("appUpdated", this.showRefreshUI, {
      once: true,
    });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        console.log("Refreshing...");
        window.location.reload();
      });
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.service = e;
      this.visible = true;
    },

    refreshApp() {
      this.visible = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>