
import Vue from 'vue'

import { jsPDF } from "jspdf";

class Doc {


  constructor() { }




  printView(url, id, options) {
    const popupWin = window.open(
      url,
      "_blank",
      "width=1920,height=1800,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
    );
    popupWin.window.focus();
  }



  print() {
    window.print();
    setTimeout(function () {
      window.close();
    }, 1000);
  }

  convertToHtml(text, vars) {
    if (text) {
      return text.replace(/ql-align/g, "text");
    }
  }

  download(doc, filename) {
    var pdfjs = new jsPDF('l', 'mm', [1200, 1210]);
    pdfjs.html(doc, {
      callback: function (pdf) {
        pdf.save(filename + ".pdf");
      },
    });
  }

  share(doc, filename) {
    var pdfjs = new jsPDF('l', 'mm', [1200, 1210]);
    pdfjs.html(doc, {
      callback: function (pdf) {
        var blob = pdf.output('blob');

        var filesArray = [new File([blob], filename + ".pdf", { type: "application/pdf" })];


        var document = {
          files: filesArray,
          title: filename,
          text: filename,
        };
        navigator.share(document);
      },
    });
  }

}

export default Doc;
