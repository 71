
<template>
  <v-main style="height: 100%">
    <slot></slot>
  </v-main>
</template>

<script>

import AppLogo from "@/components/app/sections/AppLogo";

export default {
  components : { AppLogo }
};
</script>
