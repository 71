import Vue from 'vue'

export default {

    namespaced: true,
  
    state: () => ({
    
      offices : []
  
    }),
  
    mutations: {
      setOffices(state, offices) {
        state.offices = offices
      }
    },
  
    actions: {
      async getOffices({ commit }) {
        await Vue.$http.index('company/office').then(response => {
          commit('setOffices', response.offices)
        })
      }
    }
  }