
import store from '../store'
import Vue from 'vue';

const acl = {

  can(permission) {

    if (this.companyUser().is_owner) {
      return true
    }

    if (this.companyUser().is_admin) {
      return true;
    }

    return this.hasPermission(permission)
  },

  hasPermission(permission) {

    let permissions = this.companyUser().permissions

    if (permissions) {

      if (typeof permission === 'string') {
        return permissions[permission] == true
      }

      if (typeof permission === 'function') {
        return permission(this.companyUser)
      }

    }

    return false
  },

  company() {
    return store.state.auth.company
  },

  user() {
    return store.state.auth.user
  },

  companyUser() {
    return store.state.auth.company_user
  },

  isClinic() {
    return this.company().type == 'clinic'
  },
  isBetaTester() {
    return this.company().is_beta_tester
  },

  isProfessional() {
    return this.companyUser().is_professional == 1
  },

  isAttendant() {
    return this.companyUser().is_attendant == 1
  },
  isOwner() {
    return this.companyUser().is_owner == 1
  },

  //isClinc with Preofesos
  isClinicWithProfessionals() {

    return this.isClinic() && this.company().active_professionals_count != 0
  },

  // permissions:
  // can_manage_all_calendar
  // can_manage_own_calendar
  // can_manage_all_calendar
  // can_store_customers

  // can_manage_all_financial
  // can_view_all_financial
  // can_manage_own_financial

  // removed can_access_financial

  canViewTransactions() {
    // if is professional or can manage all financial or view all financial
    return this.isProfessional() || this.can('can_manage_all_financial') || this.can('can_view_all_financial')
  },

  forceFilterOwnTransactions() {
    // if is professional and can't manage all financial nor can_view_all_financial
    if (!this.isProfessional()) {
      return false
    }

    if (!this.can('can_manage_all_financial') && !this.can('can_view_all_financial')) {
      return true
    }
  },

  canCreateTransactions() {
    // if has permission can_manage_all_financial
    return this.can('can_manage_all_financial')
  },

  canUpdateTransaction(professionalId = null) {
    // if can_manage_all_financial return true, 
    // or if is professional and can_manage_own_financial and is the same professional
    if (this.can('can_manage_all_financial')) {
      return true
    }

    if (this.isProfessional() && this.can('can_manage_own_financial') && this.user().id == professionalId) {
      return true
    }

    return false
  },

  canViewCustomerSensitiveData(professionalId) {

    if (this.isClinic()) {
      return this.user().id == professionalId
    }

    return true
  },

  isSubscriber() {
    if (this.user().is_admin) {
      return true;
    }

    if (this.company().last_subscription) {
      return this.company().last_subscription != null && this.company().last_subscription.status == 'paid'
    }


  }

}

export default acl;