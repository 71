import Vue from 'vue'
export default {

  namespaced: true,

  state: () => ({

    page: 1,

    unreadNotifications: [],
    unreadNotification: {
      data: {}
    },
    notifications: {
      data: []
    },

  }),

  mutations: {
    async addUnreadNotification(state, notification) {
      await Vue.$auth.getLoginData()
      this.commit('notification/showAlertNotification', notification)
      // state.unreadNotifications.push(notification.data)
      this.dispatch('notification/getNotifications',)

    },

    setUnreadNotifications(state, notifications) {
      state.unreadNotifications = notifications
    },

    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    showAlertNotification(state, notification) {
      state.unreadNotification = notification
    }
  },

  getters: {
    hasUnreadNotifications: state => {
      // return (state.user !== null)
    },

    unreadNotificationsCount: state => {
      return state.unreadNotifications.length
    },
  },

  actions: {
    listenToNotifications() {

    },

    getNotifications({ dispatch }, page) {
      Vue.$http.index('notification/notification', { page: page }).then((response) => {
        this.commit('notification/setNotifications', response.notifications)

      })
    },

    markAllNotificationsAsRead({ dispatch }) {
      return Vue.$http.store('notification/notification').then((response) => {
        Vue.$auth.getLoginData();

      })
    },

    markNotificationAsRead({ dispatch }, notification_id) {
      return Vue.$http.update('notification/notification', notification_id).then((response) => {
        Vue.$auth.getLoginData();
      })
    }
  }

}