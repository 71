import Vue from 'vue'

export default {

    namespaced: true,
  
    state: () => ({
    
      tags : []
  
    }),
  
    mutations: {
      setTags(state, tags) {
        state.tags = tags
      }
    },
  
    actions: {
      async getTags({ commit }) {
        await Vue.$http.index('tag/tag').then(response => {
          commit('setTags', response)
        })
      }
    }
  }