

export default [
  {
    path: "/assinar-planos-antigos-30-09-2022",
    name: "assinar",
    meta: { layout: "subscriptionLayout", name: "assinar" },
    component: () => import("@/views/SubscribeForm.vue"),
  },
  {
    path: "/assinatura/checkout",
    name: "assinaturaCheckout",
    meta: { layout: "subscriptionLayout", name: "assinar" },
    component: () => import("@/views/assinatura/CheckoutV2.vue"),
  },
  {
    path: "/assinatura/sucesso",
    name: "assinaturaSucesso",
    meta: { layout: "subscriptionLayout", name: "assinar" },
    component: () => import("@/views/assinatura/Sucesso.vue"),
  },


]
