<template>
  <app-text-field
    v-bind="$attrs"
    hide-details="auto"
    v-on="$listeners"
    :dense="dense"
    :type="type"
    
    @click:append="toggleShowPassword()"
  >
    <template v-for="(index, scopedSlot) in $scopedSlots" #[scopedSlot]="data">
      <slot :name="scopedSlotName" v-bind="data" />
    </template>
    <template v-for="(index, slot) in $slots" #[slot]>
      <slot :name="slot" />
    </template>
       <template v-slot:append>
          <app-icon @click="toggleShowPassword">{{ icon }}</app-icon>
       </template>
  </app-text-field>
</template>

<script>
export default {
  props: {
    dense: {
      default: true,
    },
  },

  data : () => ({
    showPassword : false
  }),
  computed : {
    type() {
      return this.showPassword ? 'text' : 'password'
    },
    icon() {
      return this.showPassword ? 'visibility_off' : 'visibility'
    }
  },
  methods : {
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    }
  }
};
</script>