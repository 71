import Vue from 'vue'
export default {

  namespaced: true,

  state: () => ({

    hasCustomer: true,
    hasSession: true,

    step: 1,


  }),

  getters: {
    step: state => state.step,
  },

  mutations: {
    setOnboardingData(state, company) {
      if (company.customers_count == 0) {
        state.hasCustomer = false
      }

      if (company.sessions_count == 0) {
        state.hasSession = false
      }
    },

    customerStored(state) {
      state.hasCustomer = true
    },

    sessionStored(state) {
      state.hasSession = true
    },

    nextStep(state) {
      state.step++
    },
    backStep(state) {
      state.step--
    }
  },

}