<template>
  <app-mask-field
    v-bind="$attrs"
    hide-details="auto"
    v-on="$listeners"
    dense
    label="CEP"
    @input="handleInput($event)"
    :loading="loading"
    mask="#####-###"
    autocomplete="off"
  />
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    loading: false,
  }),

  methods: {
    handleInput(cep) {
      if (cep.length == 9) {
        this.searchCep(cep);
      }
    },

    async searchCep(cep) {
      this.loading = true;

      await axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          this.handleCepInput(response.data);
        });

      this.loading = false;
    },
    handleCepInput(address) {
      this.$emit("address", {
        city: address.localidade,
        address: address.logradouro,
        district: address.bairro,
        state: address.uf,

        city_code: address.ibge,
        postal_code: address.cep,
      });
    },
  },
};
</script>