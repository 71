<template>
  <v-btn
    :outlined="!confirmState"
    color="error"
    class="mr-auto"
    rounded
    @click="handleCancel()"
  >
    <v-icon v-if="icon && !confirmState" left v-text="icon">
      {{ icon }}
    </v-icon>
    {{ textState }}</v-btn
  >
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Cancelar",
    },
    icon: {
      default: null,
    },
  },

  data: () => ({
    confirmState: false,
  }),

  computed: {
    textState() {
      return this.confirmState ? "Tem certeza ?" : this.text;
    },
  },

  watch: {
    confirmState(val) {
      if (val == true) {
        setTimeout(() => (this.confirmState = false), 2000);
      }
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmState) {
        this.$emit("click");
        this.reset();
      } else {
        this.confirmState = true;
      }
    },

    reset() {
      this.confirmState = false;
    },
  },
};
</script>

<style>
</style>