<template>
  <app-text-field
    v-model="number"
    v-bind="$attrs"
    @input="handleInput()"
    :dense="dense"
    type="tel"
    :hide-details="hideDetails"
  >
    <template v-for="(index, scopedSlot) in $scopedSlots" #[scopedSlot]="data">
      <slot :name="scopedSlotName" v-bind="data" />
    </template>
    <template v-for="(index, slot) in $slots" #[slot]>
      <slot :name="slot" />
    </template>
  </app-text-field>

  <!-- <app-mask-field
    v-bind="$attrs"
    :mask="['(##) #####-####', '(##) ####-####']"
    v-on="$listeners"
  /> -->
</template>

<script>
import PhoneNumber from "awesome-phonenumber";
export default {
  props: {
    value: {},
    hideDetails: {
      default: "auto",
    },
    dense: {
      default: true,
    },
  },

  data: () => ({
    number: null,
    country: { country: "Brasil ", isoCode: "BR", ddiCode: "55" },
  }),

  watch: {
    value(value) {
      this.setPhone(this.getNumber(value));
    },
  },

  created() {
    this.setPhone(this.getNumber(this.value));
  },

  methods: {
    getNumber(number) {
      if (number && number[0] !== "+") {
        number = "+" + number;
      }
      return number;
    },
    setPhone(value) {
      this.number = value;
    },

    handleInput() {
      var number = PhoneNumber(
        this.number.toString(),
        this.country.isoCode.toString()
      ).getNumber("international");

      this.$emit("input", number == undefined ? null : number);
    },
  },
};
</script>