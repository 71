import store from './../store'
import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

class Broadcast {

  constructor(options) {
    
    this.echo = new Echo(options)
  }

  listenToNotifications(userId) {
    
    this.echo.private(`App.Models.User.${userId}`)
      .notification((notification) => {
    
        store.commit('notification/addUnreadNotification', notification)
      });
  }

}

export default Broadcast;