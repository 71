import Vue from 'vue'
import Hotjar from 'vue-hotjar'

if (process.env.NODE_ENV === 'production') {

  Vue.use(Hotjar, {
    id: '2881273'
  })

}

export default (_) => { }