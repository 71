<template>
  <v-dialog
    width="400"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
  >
    <v-card>
      <v-card-title> Selecione um pais </v-card-title>
      <v-card-text>
        <app-text-search-field appendIcon="" @input="search($event)" />

        <v-list dense>
          <v-list-item
            v-for="(country, i) in countries"
            @click="handleInput(country)"
            :key="i"
          >
            <v-list-item-icon class="flagCountry">
              <span :class="country.isoCode.toLowerCase()" class="vti__flag" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ getText(country) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PhoneCodesList from "@/enums/PhoneCodesList";
export default {
  data() {
    return {
      dialog: false,
      countries: [],
    };
  },

  methods: {
    open() {
      this.search("a");
      this.dialog = true;
    },

    getText(country) {
      return `(+${country.ddiCode})  ${country.country}`;
    },
    search(v) {
      this.searchCountry(v)
        .then((response) => {
          this.countries = response;
        })
        .catch((error) => {});
    },

    searchCountry(v) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(
            PhoneCodesList.filter((e) => {
              return (
                (e.country || "")
                  .toLowerCase()
                  .indexOf((v || "").toLowerCase()) > -1
              );
            })
          );
        }, 500);
      });
    },

    handleInput(country) {
      this.$emit("input", country);
      this.dialog = false;
    },
  },
};
</script>
<style src="@/assets/style/flags.css"></style>

<style lang="sass">
.flagCountry

  justify-content: end
  align-items: center

// .v-list-item--dense .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon
//   width: 24px
//   height: 24px
</style>

<!--template>
  <v-dialog
    height="5000"
    max-width="600"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>
        Selecione um pais
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="country"
          @input="setCode"
          :items="countries"
          outlined
          dense
          flat
          return-object
          item-text="country"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</-template>

<script>
import PhoneCodesList from "@/enums/PhoneCodesList";
export default {
  data() {
    return {
      countries: PhoneCodesList,
      country: { country: "Brasil ", isoCode: "BR", ddiCode: "55" },
      dialog: false,
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
    setCode(country) {
      this.$emit("input", country);
      this.dialog = false;
    },
  },
};
</script>

<style></style>
