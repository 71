<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
    <app-alert />
    <AppSnackbar />

    <error-handler />
    <app-loading />
  </v-app>
</template>

<script>
import WhiteLayout from "@/layouts/WhiteLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthLayoutV2 from "@/layouts/AuthLayoutV2.vue";
import SubscriptionLayout from "@/layouts/SubscriptionLayout.vue";
import VideoCallLayout from "@/layouts/VideoCallLayout.vue";
import CustomerAppLayout from "@/layouts/CustomerAppLayout.vue";
import SetupLayout from "@/layouts/SetupLayout.vue";
import AppAlert from "./components/app/sections/AppAlert.vue";
import ErrorHandler from "./components/app/sections/ErrorHandler.vue";
import AppLoading from "./components/app/sections/AppLoading.vue";
import AppSnackbar from "./components/app/sections/AppSnackbar.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import PrintLayout from "@/layouts/PrintLayout.vue";

export default {
  components: {
    AppLayout,
    WhiteLayout,
    AuthLayout,
    AuthLayoutV2,
    SubscriptionLayout,
    VideoCallLayout,
    CustomerAppLayout,
    SetupLayout,
    ErrorHandler,
    AppAlert,
    AppLoading,
    AppSnackbar,
    AdminLayout,
    PrintLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },

  created() {
    if (process.env.NODE_ENV === "production") {
      console.log(
        "%c Powered by: Apex Software \n https://apexsoftware.com.br",
        "background: #111C29; color: #fff"
        //
      );

      this.$tracker.init();
    }

    window.addEventListener("offline", (e) => {
      this.$store.commit("app/setIsOffline", true);
    });

    window.addEventListener("online", (e) => {
      this.$store.commit("app/setIsOffline", false);
    });

    // window.addEventListener("beforeunload", (event) => {
    //   if (this.$store.state.app.hasUnsavedChanges) {
    //     event.preventDefault();
    //     event.returnValue = "";
    //     alert("Atenção: As alterações no formulário não foram salvas.");
    //   }
    // });
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap"); */

* {
  /* font-family: "Quicksand", sans-serif !important; */
  /* font-family: "Source Sans Pro", sans-serif; */
  font-family: "Poppins", sans-serif;
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
}
body {
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
}

/* @font-face {
  font-family: "Quicksand", sans-serif !important;
  src: url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap")
    format("opentype");
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
