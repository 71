import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    tabs: [],
    query: {
      tabId: 1,
    },
  }),

  getters: {
    folders: (state) =>
      state.tabs
        .find((item) => item.id == state.query.tabId)
        ?.folders.filter((item) =>
          !Vue.$admin.isAdmin() ? item.support_materials_count > 0 : true
        ),

    support_materials: (state) =>
      state.tabs.find((item) => item.id == state.query.tabId)
        ?.support_materials,
  },

  mutations: {
    setQuery(state, query) {
      state.query = query;
    },
  },

  actions: {
    async getTabs({ state }) {
      await Vue.$http
        .index("support-material/tabs")
        .then(
          (response) => (state.tabs = JSON.parse(JSON.stringify(response.tabs)))
        );
    },
  },
};
