import Vue from 'vue'

export default {

  namespaced: true,

  state: () => ({

    userData: null,

    user: null,
    company: null,
    company_user: null,

  }),

  mutations: {
    setUserData(state, data) {
      state.userData = data

      state.user = data.user
      state.company = data.company
      state.company_user = data.user.company_user

      this.commit('notification/setUnreadNotifications', data.user.unread_notifications)
      this.commit('subscription/setCompany', data.company)
      this.commit('onboarding/setOnboardingData', data.company)

      Vue.$bugsnag.setUser(data.user.id, data.user.email, data.user.name, data.company.id)

      Vue.$broadcast.listenToNotifications(data.user.id)

      Vue.$tracker.setUser({ id: data.company.id, name: data.company.name })
    },

    removeUserData(state) {
      state.userData = null
      state.user = null
      state.company = null
      state.company_user = null
    }
  },

  getters: {

    isLoggedIn: state => {
      return (state.userData !== null)
    },

    customerAlias: state => {
      if (state.company) {
        return (state.company.customer_alias == 'patient') ? 'Paciente' : 'Cliente'
      }
    },

    userFirstName: state => {
      if (state.user) {
        return state.user.name.split(' ').shift();
      }
    }
  },
}