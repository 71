
// import WebToNative from '@/utils/webtonative'
import webtonative from "webtonative";
import Vue from 'vue';
Vue.use(webtonative);

Vue.use({
  install(Vue) {
    Vue.prototype.$webtonative = webtonative
    Vue.$webtonative = webtonative;
  }
})

export default (_) => { }