<template>
  <span id="app-title">
    <slot />
  </span>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
#app-title
  color: #000
  font-family: Poppins
  font-size: 18px
  font-style: normal
  font-weight: 700
</style>