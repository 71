<template>
  <div v-if="transaction">
    <small
      :class="['mr-1', statuses[transaction.type][transaction.status].color]"
      v-bind="$attrs"
      v-on="$listeners"
      >{{ statuses[transaction.type][transaction.status].text }}</small
    >
    <v-icon
      small
      v-bind="$attrs"
      v-on="$listeners"
      v-if="!hideIcon"
      :color="statuses[transaction.type][transaction.status].iconColor"
    >
      {{ statuses[transaction.type][transaction.status].icon }}
    </v-icon>
  </div>
</template>

<script>
export default {
  props: {
    transaction: {},
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statuses: {
        in: {
          paid: {
            icon: "mdi-check-circle",
            text: "Recebido",
            color: "success--text",
            iconColor: "success",
          },
          canceled: {
            icon: "mdi-close",
            text: "Cancelado",
            color: "error--text",
            iconColor: "error",
          },

          pending: {
            icon: "mdi-clock",
            text: "A Receber",
            color: "text--secondary",
            iconColor: "secondary",
          },
        },

        out: {
          paid: {
            icon: "mdi-check-circle",
            text: "Pago",
            color: "success--text",
            iconColor: "success",
          },
          pending: {
            icon: "mdi-clock",
            text: "A Pagar",
            color: "text--secondary",
            iconColor: "secondary",
          },
          canceled: {
            icon: "mdi-close",
            text: "Cancelado",
            color: "error--text",
            iconColor: "error",
          },
        },
      },
    };
  },
};
</script>

<style>
</style>