import store from './../store'

export function customerAlias() {
    try {
        if (store.state.auth.company.customer_alias == 'patient') {
            return 'Paciente'
        } else {
            return 'Cliente'
        }
    } catch (error) {
        return 'Cliente'
    }
}