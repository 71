<template>
  <v-icon
    v-if="!custom"
    v-bind="$attrs"
    v-on="$listeners"
    class="material-symbols-rounded"
  >
    <slot />
  </v-icon>
</template>

<script>
export default {
  props: {
    custom: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {},
};
</script>

<style>
</style>