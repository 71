<template>
  <div>
    <app-text-field
      v-if="!$vuetify.breakpoint.mobile"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="date"
      type="date"
    />

    <v-dialog
      v-if="$vuetify.breakpoint.mobile"
      ref="menu"
      v-model="menu"
      :return-value.sync="date"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <app-text-field
          :value="$format.dateBr(date)"
          :label="label"
          append-icon="mdi-calendar"
          readonly
          :rules="$attrs.rules"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="on"
        />
      </template>
      <v-date-picker @input="setSelectedDate($event)" v-model="date" scrollable>
        <v-btn text color="primary" @click="setToday()"> Hoje </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { format, parse, parseISO } from "date-fns";

export default {
  props: {
    value: {},
  },
  data() {
    return {
      date: format(new Date(), "yyyy-MM-dd"),
      menu: false,
    };
  },
  watch: {
    value() {
      this.date = this.value;
    },
  },

  created() {
    this.date = this.value;
  },

  computed: {
    label() {
      return this.$attrs.label || "Data";
    },
  },
  methods: {
    setToday() {
      let date = format(new Date(), "yyyy-MM-dd");

      this.$refs.menu.save(date);

      this.setSelectedDate(date);
    },
    setSelectedDate(selectedDate) {
      // this.date = selectedDate;
      this.$refs.menu.save(selectedDate);

      this.$emit("input", selectedDate);
      // this.dialog = false;
    },
  },
};
</script>

<style>
</style>
<!--template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="290px" 
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="computedDateFormatted"
          label="Data"
          prepend-inner-icon="mdi-chevron-left"
          append-icon="mdi-chevron-right"
          readonly
          solo
          flat
          background-color="grey lighten-4"
          v-on="on"
          @click:prepend-inner="previous()"
          @click:append="next()"
        ></v-text-field>
      </template>
      <v-date-picker
        locale="pt-Br"
        no-title
        scrollable
        v-model="date"
        @input="$refs.menu.save(date)"
        :type="type"
      >
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-menu>
  </div>
</!--template>

<script>
export default {
  props: {
    value: {},

    dateRangeType: {
      default: "date",
    },

    type: {
      default: "date",
    },
  },

  data: () => ({
    date: null,

    dateFormatted: null,

    menu: false,
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    value(val) {
      this.date = val;
    },

    date(val) {
      this.dateFormatted = this.formatDate(this.date);
      console.log(val);
      this.$emit("input", val);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");

      return this.type == "date"
        ? `${day}/${month}/${year}`
        : `${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    previous() {
      console.log("prev");
    },

    next() {
      console.log("besd");
    },
  },
};
</script>
