<template>
  <div id="AppWhatsappField">
    <app-text-field
      v-model="nationalPhoneNumber"
      @input="handleInput()"
      @change="handleValidate()"
      v-bind="$attrs"
      type="tel"
      :hide-details="isValid"
      messages="Verifique o número digitado está correto."
    >
      <template v-slot:prepend-inner>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <i
              v-on="on"
              v-bind="attrs"
              class="vti__flag"
              :class="isoCode.toLowerCase()"
            />
            <span>+{{ country.ddiCode }}</span>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in countries"
              :key="index"
              @click="setCountry(item)"
            >
              <v-list-item-icon>
                <span :class="item.isoCode.toLowerCase()" class="vti__flag" />
              </v-list-item-icon>
              <v-list-item-title> {{ item.country }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleChangeCountry()">
              <v-list-item-icon>
                <v-icon>mdi-earth</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Mais Opções </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </app-text-field>
    <AppSelectCountry @input="setCountry($event)" ref="AppSelectCountry" />
  </div>
</template>

<script>
import awesomePhoneNumber from "awesome-phonenumber";
import PhoneCodesList from "@/enums/PhoneCodesList";
import AppSelectCountry from "@/components/app/sections/AppSelectCountry";

export default {
  components: {
    AppSelectCountry,
  },

  props: {
    value: {},
  },

  data: () => ({
    countries: [
      { country: "Brasil ", isoCode: "BR", ddiCode: "55" },
      { country: "Estados Unidos (EUA)", isoCode: "US", ddiCode: "1" },
    ],

    completePhoneNumber: null,
    nationalPhoneNumber: null,

    isoCode: "BR",
    country: { country: "Brasil ", isoCode: "BR", ddiCode: "55" },
    isValid: true,
  }),

  watch: {
    value(value) {
      this.setPhoneData(this.getNumber(value));
    },
  },

  created() {
    this.setPhoneData(this.getNumber(this.value));
  },

  computed: {
    numberInput() {
      if (this.nationalPhoneNumber) {
        return awesomePhoneNumber(
          this.nationalPhoneNumber.toString(),
          this.isoCode
        ).getNumber("international");
      }
    },
  },

  methods: {
    getNumber(number) {
      if (number && number[0] !== "+") {
        number = "+" + number;
      }
      return number;
    },

    setPhoneData(completePhoneNumber) {
      if (completePhoneNumber) {
        this.isoCode = this.getIsoCode(completePhoneNumber.toString());

        this.country = this.getCountry(this.isoCode);

        this.nationalPhoneNumber = this.getNationalPhoneNumber(
          completePhoneNumber.toString()
        );
      }
      if (!completePhoneNumber) {
        this.nationalPhoneNumber = null;
      }
    },

    getIsoCode(completePhoneNumber) {
      return awesomePhoneNumber(completePhoneNumber).getRegionCode();
    },

    getCountry(isoCode) {
      return PhoneCodesList.find((country) => country.isoCode === isoCode);
    },

    getNationalPhoneNumber(completePhoneNumber) {
      return awesomePhoneNumber(completePhoneNumber).getNumber("national");
    },

    handleChangeCountry() {
      this.$refs.AppSelectCountry.open();
    },

    setCountry(country) {
      this.country = JSON.parse(JSON.stringify(country));

      this.isoCode = country.isoCode;

      this.handleInput();
    },

    handleInput() {
      let number = this.numberInput !== undefined ? this.numberInput : null;
      this.$emit("input", number);
    },
    handleValidate() {
      if (this.numberInput) {
        var number = this.numberInput;

        var isoCode = awesomePhoneNumber(number).getRegionCode();
        this.isValid = awesomePhoneNumber(number, isoCode).isValid();
      }
    },
  },
};
</script>
<style lang="sass"  >
#AppWhatsappField
  .v-text-field .v-input__prepend-inner
    align-self: end !important
    margin-bottom: 4px !important
</style>
<style src="@/assets/style/flags.css"></style>

