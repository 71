
import store from '../store'

const admin = {

  isAdmin() {
    return store.state.auth.user.is_admin == 1
  },

}

export default admin;