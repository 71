export default [
  {
    path: '/admin/notificacoes-whatsapp',
    name: 'WhatsappNotificationsIndex',
    meta: { layout: 'AdminLayout', name: 'Notificações do WhatsApp', icon: "mdi-whatsapp", showLink: false, showAdmin: 1 },

    component: () => import('@/views/admin/WhatsappNotificationsIndex.vue')
  },
  {
    path: '/admin/logs',
    name: 'SystemLogsIndex',
    meta: { layout: 'AdminLayout', name: 'Logs do Sistema', icon: "mdi-math-log", showLink: false, showAdmin: 1 },
    component: () => import('@/views/admin/SystemLogsIndex.vue')
  },
  {
    path: '/admin/instancias-whatsApp',
    name: 'WhatsAppInstancesIndex',
    meta: { layout: 'AdminLayout', name: 'Instâncias do WhatsApp', icon: "mdi-api", showLink: false, showAdmin: 1 },
    component: () => import('@/views/admin/WhatsAppInstancesIndex.vue')
  },
  {
    path: '/admin',
    name: 'ManagementIndex',
    meta: { layout: 'AdminLayout', name: 'Administração', icon: "admin_panel_settings", showLink: true, showAdmin: 1 },
    component: () => import('@/views/admin/ManagementIndex.vue')
  },
  {
    path: '/admin/financeiro',
    name: 'FinanceManagementIndex',
    meta: { layout: 'AdminLayout', name: 'Financeiro', icon: "monetization_on", showLink: false, showAdmin: 1 },
    component: () => import('@/views/admin/FinanceManagementIndex.vue')
  },

] 