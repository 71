import subscriptionMiddleware from "../middleware/subscriptionMiddleware";

export default [
  {
    path: "/bem-vindo",
    name: "welcomeV2",
    meta: { layout: "authLayoutV2" },
    component: () => import("@/views/app/WelcomeV2.vue"),
  },
  // {
  //   path: "/bem-vindo-v2",
  //   name: "welcomeV2",
  //   meta: { layout: "authLayoutV2" },
  //   component: () => import("@/views/app/WelcomeV2.vue"),
  // },
  {
    path: "/mauntencao",
    name: "maintenance",
    meta: { layout: "SetupLayout", auth: "guest" },
    component: () => import("@/views/app/Maintenance.vue"),
  },
  {
    path: "/materiais-bonus",
    name: "materiais-bonus",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Materiais Bônus",
      icon: "folder_special",
      showLink: false,
      permission: () => Vue.$acl.isSubscriber() && !Vue.$acl.isClinicWithProfessionals(),
    },
    component: () => import("@/views/app/MateriaisBonus.vue"),
  },
];
