export default {

  namespaced: true,

  state: () => ({

    firstAccess : false, 

    loading: false,

    alert: {
      message: null,
      id: null,
    },

    httpError: null,

    lastRoute : null,

    snackbar: {},

    isOffline: false,

    hasUnsavedChanges: false,

    loggedAsAnotherUser: false,
    
  }),

  getters: {
    appIsOffline: state => {
      return state.isOffline
    },
  },

  mutations: {
    setFistAccess(state, value) {
      state.firstAccess = value
    },
    
    alert(state, message) {
      state.alert.message = message
      state.alert.id = Math.random()
    },
    snackbar(state, props) {
      state.snackbar = props
    },
    
    setHttpError(state, error) {
      state.httpError = error
    },

    resetHttpError(state) {
      state.httpError = null
    },

    setLastRoute(state, route) {
      state.lastRoute = route
    },

    setAppLoading(state, loading) {
      state.loading = loading
    },

    setIsOffline(state, isOffline) {
      state.isOffline = isOffline
    },

    setHasUnsavedChanges(state, hasUnsavedChanges) {
      state.hasUnsavedChanges = hasUnsavedChanges
    },

    loggedAsAnotherUser(state) {
      state.loggedAsAnotherUser = true
    }
  },

  actions: {
    hasChanges({ commit, state}) {
      commit("setHasUnsavedChanges", true)
    },

    resetChanges({ commit, state }) {
      commit("setHasUnsavedChanges", false)
    }
  }
}
