<template>
  <div style="height: 100%">
    <AppAlertOffline />
    <v-app-bar
      app
      :color="appBarColor"
      elevate-on-scroll
      v-if="$vuetify.breakpoint.mobile || loggedAsAnotherUser"
    >
      <portal-target v-if="$vuetify.breakpoint.mobile" name="mobileMenuButton">
      </portal-target>

      <v-toolbar-title>
        <app-title>{{ pageTitle }}</app-title>
        <!-- <h3 class="ml-md-2 pageTitle">{{ pageTitle }}</h3> -->
      </v-toolbar-title>
      <template v-if="loggedAsAnotherUser">
        <v-chip class="ml-4" color="red" text-color="white">
          !!! Atenção Perigo (Pressione F5 para voltar) !!!
        </v-chip>
      </template>
      <v-spacer></v-spacer>

      <portal-target v-if="$vuetify.breakpoint.mobile" name="mobileLeftAppBar">
      </portal-target>
      <app-notification-alert />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      color="primary"
      dark
      floating
      :permanent="!$vuetify.breakpoint.mobile"
      :expand-on-hover="!$vuetify.breakpoint.mobile"
    >
      <v-list nav>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar size="30">
                <v-img
                  v-bind="$attrs"
                  :src="baseUrl + 'img/icons/android-chrome-512x512.png'"
                  contain
                ></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content> <app-logo /> </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider class="my-2"></v-divider>

        <v-list-item-group>
          <v-list-item to="/configuracoes/minha-conta">
            <v-list-item-avatar>
              <AppImg :src="user.media[0]" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider class="my-2"></v-divider>

        <v-list-item-group>
          <template v-for="(link, index) in links">
            <v-list-item
              v-if="showLink(link)"
              link
              :to="link.path"
              router
              exact
              :key="index"
            >
              <v-list-item-action>
                <v-badge
                  overlap
                  color="error"
                  v-if="unreadNotifications && link.meta.badge"
                  :content="unreadNotifications"
                >
                  <v-icon class="material-symbols-rounded">{{
                    link.meta.icon
                  }}</v-icon>
                </v-badge>
                <v-icon class="material-symbols-rounded" v-else>{{
                  link.meta.icon
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ getLinkName(link) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-if="$acl.isSubscriber() && !$acl.isClinicWithProfessionals()"
            link
            to="/materiais-bonus"
            router
            exact
          >
            <v-list-item-action>
              <app-icon>folder_special</app-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Materiais Bônus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- -->

          <!-- <v-list-item @click="getHelp()" link router exact>
            <v-list-item-action>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ajuda</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item @click="logout()">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="height: 100%">
      <AlertSubscription />
      <ErrorAlertSubscription />
      <v-container class="pa-md-6" fluid style="height: 100%">
        <slot></slot>
      </v-container>
      <AppAlertNewVersion />
      <AppBottomNavigation @toggle-drawer="drawer = !drawer" />
      <!-- <v-bottom-navigation
        v-if="$vuetify.breakpoint.mobile"
        grow
        app
        color="primary"
      >
        <v-btn @click.prevent="drawer = !drawer">
          <app-icon>menu</app-icon>
        </v-btn>

        <v-btn value="dashboard" to="/">
          <app-icon>dashboard</app-icon>
        </v-btn>

        <v-btn value="agenda" to="/agenda">
          <app-icon>event</app-icon>
        </v-btn>
        <v-btn value="clientes" to="/clientes">
          <app-icon>people</app-icon>
        </v-btn>

        <v-btn
          v-if="$acl.canViewTransactions()"
          value="financeiro"
          to="/financeiro"
        >
          <app-icon>monetization_on</app-icon>
        </v-btn>
      </v-bottom-navigation> -->
    </v-main>
    <change-password-form ref="ChangePasswordForm" />
  </div>
</template>

<script>
import AppLogo from "@/components/app/sections/AppLogo";
import ChangePasswordForm from "@/components/auth/forms/ChangePasswordForm";
import AppImg from "../components/app/sections/AppImg.vue";
import AppNotificationAlert from "@/components/app/sections/AppNotificationAlert.vue";
import AlertSubscription from "@/components/subscription/sections/AlertSubscription.vue";
import AppAlertNewVersion from "@/components/app/sections/AppAlertNewVersion.vue";
import AppAlertOffline from "@/components/app/sections/AppAlertOffline.vue";
import ErrorAlertSubscription from "@/components/subscription/sections/ErrorAlertSubscription.vue";
import AppBottomNavigation from "@/components/app/sections/AppBottomNavigation.vue";
export default {
  components: {
    ChangePasswordForm,
    AppLogo,
    AppImg,
    AppNotificationAlert,
    AlertSubscription,
    AppAlertNewVersion,
    AppAlertOffline,
    ErrorAlertSubscription,
    AppBottomNavigation,
  },

  mounted() {
    this.$tracker.track("AppVisited");
    this.$store.dispatch("office/getOffices");
    this.$store.dispatch("tags/getTags");
  },

  data: () => ({
    drawer: false,
  }),
  computed: {
    loggedAsAnotherUser() {
      return this.$store.state.app.loggedAsAnotherUser;
    },

    baseUrl() {
      return process.env.BASE_URL;
    },

    user() {
      return this.$store.state.auth.user;
    },
    unreadNotifications() {
      return this.$store.getters["notification/unreadNotificationsCount"];
    },

    company() {
      return this.$auth.company;
    },

    pageTitle() {
      return typeof this.$route.meta.name == "function"
        ? this.$route.meta.name()
        : this.$route.meta.name;
    },

    links() {
      return this.$router.options.routes.filter((row) => {
        if (typeof row.meta.showLink != "undefined") {
          return row.meta.showLink && this.hasPermission(row.meta.permission);
        }

        return false;
      });
    },

    appBarColor() {
      return this.$vuetify.breakpoint.mobile ? "white" : "grey lighten-3";
    },
  },
  methods: {
    showLink(link) {
      if (!link.meta.showAdmin) {
        return true;
      }

      return this.$admin.isAdmin();
    },
    hasPermission(permission) {
      if (permission) {
        return this.$acl.can(permission);
      }
      return true;
    },

    logout() {
      this.$auth.logout();
      this.$router.push("/login");
    },
    getLinkName(link) {
      return typeof link.meta.name == "function"
        ? link.meta.name()
        : link.meta.name;
    },
    changePassword() {
      this.$refs.ChangePasswordForm.open();
    },
  },
};
</script>
<style lang="sass" scoped>
.pageTitle
  font-family: 'Poppins'
  font-style: normal
  font-weight: 700
  font-size: 18px
  line-height: 27px
  color: #33303E
</style>
