import Vue from 'vue';


export default async function adminMiddleware(to, from, next) {

  if (to.meta.showAdmin) {
    if (Vue.$admin.isAdmin()) {
      return true;
    } else {
      next('/')
      return
    }
  }

}