<template>
  <div>
    <v-btn
      @click="handleXmlClick()"
      v-bind="$attrs"
      :color="color"
      v-if="!hasFile"
    >
      <component v-text="btnIcon" :is="iconPack" />
      <span v-if="showBtnText" class="ml-2">{{ btnText }}</span>
    </v-btn>
    <v-card v-if="hasFile" outlined>
      <v-card-text class="text-center py-0">
        <div class="d-flex flex-column">
          <div class="py-0">
            <div>
              <span class="text-secondary"> {{ fileName }} </span>

              <v-btn @click="deleteFile()" text icon color="error">
                <app-icon>delete</app-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <input
      class="d-none"
      ref="fileInput"
      id="fileInput"
      type="file"
      :accept="$attrs.accept"
      @input="handleFileInput()"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    file: null,
    fileName: null,
    hasFile: false,
  }),

  computed: {
    btnText() {
      return !this.hasFile ? this.label : this.fileName;
    },
    showBtnText() {
      return this.$attrs.showBtnText != null ? this.$attrs.showBtnText : true;
    },

    label() {
      return this.$attrs.label != null ? this.$attrs.label : "Abrir arquivo";
    },
    btnIcon() {
      return this.$attrs.btnIcon != null ? this.$attrs.btnIcon : "file_upload";
    },
    iconPack() {
      return this.$attrs.iconPack != null ? this.$attrs.iconPack : "app-icon";
    },
    url() {
      return this.$attrs.url != null ? this.$attrs.url : "";
    },
    fileAlias() {
      return this.$attrs.fileAlias != null ? this.$attrs.fileAlias : "file";
    },
    color() {
      return this.$attrs.color != null ? this.$attrs.color : "primary";
    },
    // hasFile() {
    //   console.log(this.file);
    //   return this.file;
    // },
  },

  methods: {
    handleXmlClick() {
      this.$refs.fileInput.click();
    },

    handleFileInput() {
      let input = this.$refs.fileInput;
      let files = input.files;
      if (files && files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.file = files[0];

          this.fileName = files[0].name;
          this.parseFile();
        };
        reader.readAsDataURL(files[0]);
      }
    },

    async parseFile() {
      this.$loading.start();

      let form = new FormData();

      form.append(this.fileAlias, this.file, this.fileAlias);

      await this.$http
        .$post(this.url, form)
        .then((response) => {
          this.hasFile = true;
          this.$emit("input", response);
          this.$loading.finish();
        })
        .catch((error) => {
          if (!this.url) {
            console.log("cade a url pra onde vai o arquivo ? (º~º) ");
          }
          this.$loading.finish();
        });
    },
    deleteFile() {
      this.file = null;
      this.fileName = null;
      this.$emit("input", []);
      this.$refs.fileInput.value = null;
    },
  },
};
</script>

<style>
</style>