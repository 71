import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app/app'
import materials from './modules/app/supportMaterial'
import auth from './modules/app/auth'
import notification from './modules/app/notification'
import onboarding from './modules/app/onboarding'
import events from './modules/app/events'
import tags from './modules/app/tags'
import office from './modules/company/office'
import customer from './modules/customers/customer'
import videoCall from './modules/sessions/videoCall'
import subscription from './modules/company/subscription'
import whatsapp from './modules/app/whatsapp'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    notification,
    onboarding,
    events,
    tags,
    office,
    customer,
    videoCall,
    subscription,
    materials,
    whatsapp
  }
})
