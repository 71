
<template>
  <v-main style="height: 100%">
    <v-container class="pa-0" fluid style="height: 100%">
      <div class="d-flex" style="height: 100%">
        <div
          class="d-md-block d-none"
          style="background-color: #9e74da; width: 60%"
        >
          <v-img
            height="100%"
            src="../assets/images/background-woman-therapist.jpg"
          ></v-img>
        </div>
        <div class="flex-grow-1" style="background-color: #fff">
          <v-row class="row justify-center h-100">
            <v-col md="8" style="padding-top: 100px" class="pr-0">
              <slot></slot>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>

import AppLogo from "@/components/app/sections/AppLogo";

export default {
  components : { AppLogo }
};
</script>
