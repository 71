<template>
  <div>
    <v-main class="page">
      <v-app-bar color="primary" app flat dark>
        <v-toolbar-title><app-logo /></v-toolbar-title>
      </v-app-bar>
      <v-container class="mt-2 mt-md-5" style="max-width: 1350px">
        <slot></slot>
      </v-container>
    </v-main>
    <v-footer absolute app>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Psicoplanner</strong>
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import AppLogo from "@/components/app/sections/AppLogo";

export default {
  components: {
    AppLogo,
  },
};
</script>

<style lang="sass" scoped>
.page
  background-color: #fff
.component
  margin-top: 5%
  align-items: center
  justify-content: center
  align-content: center
</style>
