import Vue from 'vue'
import Tracker from '../utils/tracker';


Vue.use({
  install(Vue) {
    
    let tracker = new Tracker({
      
      mixpanel : {
        key : process.env.VUE_APP_MIXPANEL_KEY,
        options : {debug: true},
      },

      pixel : {
        key : process.env.VUE_APP_PIXEL_KEY
      }

    })

    Vue.prototype.$tracker = tracker
    Vue.$tracker = tracker;

  }
})

export default (_) => { }
