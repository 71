import subscriptionMiddleware from '@/router/middleware/subscriptionMiddleware';
import { customerAlias } from "@/utils/customerAlias";

export default [
  {
    path: "/clientes",
    name: "customers",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout", name: () => `${customerAlias()}s`, icon: "people", showLink: true
    },
    component: () => import("@/views/clientes/index.vue"),
  },
  {
    path: `/cliente/:id`,
    name: "customeProfile",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Ficha do ${customerAlias()}`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/CustomerProfileV2.vue"),
  },
  {
    path: `/cliente/ficha-cadastral/:id`,
    name: "cliente-fichaCadastral",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Dados do ${customerAlias()}`,
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/cliente/FichaCadastral.vue"
      ),
  },
  {
    path: `/cliente/prontuario/:id`,
    name: "cliente-prontuario",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Prontuário`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cliente/Prontuario.vue"),
  },
  {
    path: `/cliente/anamnese/:id`,
    name: "cliente-anamnese",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Anamnese`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cliente/Anamnese.vue"),
  },
  {
    path: `/cliente/sessoes/:id`,
    name: "cliente-sessoes",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Anotações da sessão`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cliente/Sessoes.vue"),
  },
  {
    path: `/cliente/financeiro/:id`,
    name: "cliente-financeiro",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Financeiro`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cliente/Financeiro.vue"),
  },
  {
    path: `/cliente/documentos/:id`,
    name: "cliente-documentos",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Documentos`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cliente/Documentos.vue"),
  },
  {
    path: `/cliente/notificacoes-cobranca/:id`,
    name: "notificacoes-cobranca",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Notificações de cobrança`,
    },
    component: () => import("@/views/cliente/NotificacoesCobranca.vue"),
  },
  {
    path: `/cliente/arquivos/:id`,
    name: "cliente-arquivos",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => `Arquivos`,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/cliente/Arquivos.vue"),
  },
  {
    path: "/cliente/:id/imprimir/prontuario",
    name: "CustomerProfessionalRecordPrint",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "printLayout",
      name: () => `Prontuario do ${customerAlias()}`,
    },
    component: () =>
      import("@/views/printers/CustomerProfessionalRecordPrint.vue"),
  },
  {
    path: "/cliente/:id/imprimir/anamnese",
    name: "CustomerAnamnesePrint",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "printLayout",
      name: () => `Anamnese do ${customerAlias()}`,
    },
    component: () => import("@/views/printers/CustomerAnamnesePrint.vue"),
  },
  {
    path: "/clientes/importar",
    name: "CustomerImport",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => customerAlias(),
      icon: "people",
      false: true,
    },
    component: () => import("@/views/CustomerImport.vue"),
  },
  {
    path: "/clientes/importar-via-planilha",
    name: "CustomerImportXLSX",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: () => customerAlias(),
      icon: "people",
      false: true,
    },
    component: () => import("@/views/CustomerImportXLSX.vue"),
  },
];
