import Vue from 'vue'
import { differenceInCalendarDays, parseISO, formatISO } from "date-fns";

export default {

  namespaced: true,

  state: () => ({
    company: {},
    subscription: null
  }),

  mutations: {
    setCompany(state, company) {
      state.company = company
      state.subscription = company.last_subscription
    },
  },

  getters: {

    isFreeAccount(state) {
      return state.company.is_free_account == 1
    },

    hasSubscription(state) {
      return (state.subscription !== null)
    },

    isOnTrial(state, getters) {
      if (getters.hasSubscription) {
        return false
      }

      return true
    },

    daysToEndTrial(state) {
      return differenceInCalendarDays(parseISO(state.company.free_trial_ends_at), parseISO(formatISO(new Date())));
    },

    isTrialEnded(state, getters) {
      return getters.daysToEndTrial < 0
    },

    daysToEndSubscription(state) {
      if (state.subscription && state.subscription.ends_at) {
        return differenceInCalendarDays(parseISO(state.subscription.ends_at), parseISO(formatISO(new Date())));
      }
    },

    isSubscriptionEnded(state, getters) {
      if (getters.daysToEndSubscription && getters.daysToEndSubscription < 0) {
        return true
      }

      if (state.subscription.status != 'paid') {
        return true
      }

      return false
    },

    isSubscriptionPastDue(state) {
      return state.subscription.status == 'past_due' || state.subscription.status == 'unpaid'
    },

    isSubscriptionBlocked(state) {
      return state.subscription.status == 'unpaid' || 'canceled' || 'ended'
    },

    isSubscriptionActive(state) {
      return state.subscription.status == 'paid'
    },

    hasActiveSubscription(state, getters) {
      return getters.hasSubscription && getters.isSubscriptionActive
    },

    byPassBlock(state) {
      if (state.company.no_block_access_before) {
        let days = differenceInCalendarDays(parseISO(state.company.no_block_access_before), parseISO(formatISO(new Date())));

        return (days >= 0)
      }

      return false
    },

    blocked(state, getters) {
      var blocked = false

      //  uncomment para bloquear por falta de pagamento
      // if (getters.hasSubscription && (getters.isSubscriptionEnded || getters.isSubscriptionBlocked)) {
      //   blocked = true
      // }

      if (getters.isOnTrial && getters.isTrialEnded) {
        blocked = true
      }

      if (getters.byPassBlock) {
        blocked = false
      }

      if (getters.isFreeAccount) {
        blocked = false
      }

      return blocked
    },

    canSubscribe(state, getters) {
      return getters.isOnTrial || state.subscription.status == 'canceled'
    },

    subscriptionIsActive(state, getters) {
      return getters.hasSubscription && state.subscription.status !== 'canceled';
    },

    incomplete(state, getters) {
      return getters.hasSubscription && state.subscription.status == 'incomplete'
    },

    forcePayment(state, getters) {
      return getters.hasSubscription && state.subscription.force_payment
    },


  }

}