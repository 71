export default
  [
    {
      path: '/acesso-negado',
      name: 'acesso-negado',
      meta: { layout: 'WhiteLayout', isLock: true },
      component: () => import('@/views/locks/AccessDeniedIndex.vue')
    },
    {
      path: '/assinatura',
      name: 'assinatura',
      meta: { layout: 'WhiteLayout', isLock: true },
      component: () => import('@/views/locks/SubscriptionAlertIndex.vue')
    },
    {
      path: '*',
      name: '*',
      meta: { layout: 'WhiteLayout', isLock: true },
      component: () => import('@/views/locks/PageNotFoundIndex.vue')
    },
  ]
