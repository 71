import Vue from "vue";
import VueRouter from "vue-router";

import VueRouteMiddleware from "vue-route-middleware";
import authMiddleware from "./middleware/authMiddleware";
import navigationMiddleware from "./middleware/navigationMiddleware";
import adminMiddleware from "./middleware/adminMiddleware";
import subscriptionMiddleware from "./middleware/subscriptionMiddleware";

import admin from "./routers/admin";
import auth from "./routers/auth";
import locks from "./routers/locks";
import clientes from "./routers/clientes";

import financeiro from "./routers/financeiro";
import configuracoes from "./routers/configuracoes";
import ajuda from "./routers/ajuda";
import app from "./routers/app";

import assinatura from "./routers/assinatura";

Vue.use(VueRouter);

const routes = [
  ...auth,
  ...locks,
  ...app,
  ...assinatura,
  {
    path: "/",
    name: "Home",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Dashboard",
      icon: "dashboard",
      showLink: true,
    },
    component: () => import("../views/DashboardIndex.vue"),
  },
  ...clientes,

  // {
  //   path: "/setup",
  //   name: "setup",
  //   meta: { layout: "setupLayout" },
  //   component: () =>
  //     import("../views/Setup.vue"),
  // },

  {
    path: "/agenda",
    name: "schedule",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Agenda",
      icon: "event",
      showLink: true,
    },
    component: () => import("../views/agenda"),
  },
  ...financeiro,
  {
    path: "/agenda-beta",
    name: "schedule-beta",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Agenda",
      icon: "event",
      showLink: false,
    },
    component: () => import("../views/SessionIndexNew.vue"),
  },
  {
    path: "/videochamada/:uuid",
    name: "customer-video-call",
    meta: { layout: "videoCallLayout", name: "Video Chamada", auth: "guest" }, // todo remove this
    component: () => import("../views/VideoCall.vue"),
  },
  // {
  //   path: '/videochamada/:uuid',
  //   name: 'customer-video-call',
  //   meta: { layout: 'videoCallLayout', name: 'Video Chamada', auth: 'guest' },
  //   component: () => import('../views/CustomerVideoCall.vue')
  // },
  {
    path: "/videochamada/psicologo/:uuid",
    name: "psychologist-video-call",
    meta: { layout: "videoCallLayout", name: "Video Chamada", auth: "guest" }, // todo remove this
    component: () => import("../views/VideoCall.vue"),
  },


  {
    path: "/notificacoes",
    name: "Notificações",
    meta: {
      layout: "appLayout",
      name: "Notificações",
      icon: "notifications",
      badge: true,
      showLink: true,
    },
    component: () => import("../views/NotificationIndex.vue"),
  },
  {
    path: "/confirmar-presenca/:uuid",
    name: "Confirmar Presenca",
    meta: {
      layout: "CustomerAppLayout",
      name: "Confirmar Presença",
      auth: "guest",
    }, // todo remove this
    component: () => import("../views/ConfirmCustomerPresence.vue"),
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      middleware: [subscriptionMiddleware],
      layout: "appLayout",
      name: "Dashboard",
    },
    component: () => import("../views/DashboardIndex.vue"),
  },

  {
    path: "/documento/imprimir/:id",
    meta: { layout: "PrintLayout", name: "Administração" },
    component: () => import("../views/PrintDocumentIndex.vue"),
  },
  ...configuracoes,
  ...ajuda,

  ...admin,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  await authMiddleware(to, from, next);

  await adminMiddleware(to, from, next);

  await navigationMiddleware(to, from, next);

  // await aclMiddleware(to, from, next)

  // await subscriptionMiddleware(to, from, next)
});

router.beforeEach(VueRouteMiddleware());

export default router;
