export default {

  namespaced: true,

  state: () => ({
  
    searchParams: {
      page : 1
    }

  }),

  getters: {

  },

  mutations: {
    setSearchParams(state, searchParams) {
      state.searchParams.page = searchParams
    }
  },

  actions: {

  }
}