
import Vue from 'vue'

export default {

  namespaced: true,

  state: () => ({

    // logFbp: false,

  }),

  mutations: {
    // logFbq(state, value) {
    //   state.logFbp = value
    // }
  },

  actions: {
  //   initFbq({ commit }) {
  //     if (process.env.VUE_APP_PIXEL_KEY) {
  //       console.log('start pixel...')
        
  //       fbq('init', process.env.VUE_APP_PIXEL_KEY);
        
  //       commit('logFbq', true)
  //     }
  //   },

  //   signUp({ commit, state }, payload) {
  //     if (state.logFbp) {
  //       fbq('track', 'StartTrial', payload);
  //     }
  //   },

  //   subscribe({ commit, state }, payload) {
  //     if (state.logFbp) {
  //       fbq('track', 'Subscribe', payload);
  //     }
  //   }
  }
}