import Vue from 'vue'

export default {

  namespaced: true,

  state: () => ({

    qr_code: null,
    instance: null,

    status: null,

  }),


  getters: {
    status: state => {
      return state.status
    },


    isDisconnected: state => {
      return state.status !== 'open'
    },

    QRCode: state => {
      return state.qr_code
    },

    hasInstance: state => {
      return state.status !== 'unavailable'
    }




  },


  mutations: {
    setStatus(state, tags) {
      state.status = tags
    },
    setQRCode(state, tags) {
      state.qr_code = tags
    }
  },

  actions: {


    async getStatus({ getters }) {

      await Vue.$http.$get("whatsapp/status").then((response) => {
        this.commit('whatsapp/setStatus', response.status)
        if (response.status && getters.hasInstance) {
          if (!getters.isDisconnected) {
            setTimeout(() => {
              this.dispatch("whatsapp/getStatus")
            }, 10000);
          }
          console.log(getters.isDisconnected);
          if (getters.isDisconnected) {
            setTimeout(() => {
              this.dispatch("whatsapp/connect")
              this.dispatch("whatsapp/getStatus")
            }, 10000);
          }

        }
      });
    },

    async connect({ getters }) {
      if (getters.isDisconnected) {
        await Vue.$http.$get("whatsapp/connect").then((response) => {
          this.commit('whatsapp/setQRCode', response.qr_code)
        });
      }
    },

    async disconnect() {
      await Vue.$http.$post("whatsapp/disconnect").then((response) => {
      });
    },

    async restart() {
      await Vue.$http.$post("whatsapp/restart").then((response) => {
      });
    }

  }
}
