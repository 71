
import Admin from './../auth/Admin.js'
import Vue from 'vue';

Vue.use({
  install(Vue) {
    Vue.prototype.$admin = Admin
    Vue.$admin = Admin;
  }
})

export default (_) => { }