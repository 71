<template>
  <div style="height: 100%" class="backgroundPage">
    <v-main style="height: 100%">
      <v-container class="d-flex justify-center" style="height: 100%">
        <slot />
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isApp() {
      return this.$webtonative.platform != "WEBSITE";
    },
  },
  methods: {
    download() {
      this.$doc.download(this.$refs.document);
    },
  },
};
</script>

<style lang="sass" scoped>
.backgroundPage
  background-color: #eeeeee
.not-print
  display: flex
  justify-content: flex-center

.page
  background-color: #fff
.component
  margin-top: 5%
  align-items: center
  justify-content: center
  align-content: center
@page
  size: A4
  margin: 0.5cm
  padding: 0.5cm

@media print
  .not-print
    display: none
</style>
 