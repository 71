import store from './../../store'

export default (to, from, next) => {

  //console.log('blocked...', store.getters['subscription/blocked'])

  if (store.getters['subscription/blocked']) {
    next('/assinatura/checkout')
  }
  if (store.getters['subscription/incomplete']&& store.getters['subscription/isTrialEnded']) {
    
    next('/assinatura/checkout')
  }

  if (store.getters['subscription/forcePayment']) {
    next('/configuracoes/assinatura')
  }

  next()
}