import Vue from 'vue';
import Broadcast from './../utils/broadcast'


Vue.use({
  install(Vue) {

    const options = {
      broadcaster: process.env.VUE_APP_PUSHER_BROADCASTER,
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      forceTLS: process.env.VUE_APP_PUSHER_FORCE_TLS,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            Vue.$http.httpClient.post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name
            })
              .then(response => {
                callback(false, response.data);
              })
              .catch(error => {
                callback(true, error);
              });
          }
        };
      }
    }

    let broadcast = new Broadcast(options)

    Vue.prototype.$broadcast = broadcast
    Vue.$broadcast = broadcast;

  }
})


export default (_) => { }