<template>
  <div :id="appPaginationStyle">
    <template v-if="getResult">
      <small class="text--disabled" v-if="showCount">
        {{ data.total }} registros encontrado{{ plural }}
      </small>
      <slot />
    </template>

    <div v-if="hasPages" class="d-flex mt-5 justify-center">
      <v-pagination
        @input="$emit('click', $event)"
        :value="data.current_page"
        :total-visible="totalVisible"
        :length="data.last_page"
        circle
      />
    </div>

    <slot v-if="!getResult" style="color: gray" name="not-found">
      <div class="d-flex flex-column align-center" style="width: 100%">
        <div class="d-flex justify-center mt-10">
          <v-img src="@/assets/images/app/not_fount.svg" class="icone" />
        </div>
        <div class="text-center">
          <h2 class="mt-6">Nenhum item encontrado</h2>
          <h4 class="mt-4 mb-10 text--secondary">
            Verifique se as palavras estão corretas ou procure outros termos.
          </h4>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: function () {
        return {
          total: 0,
        };
      },
    },
    showCount: {
      default: true,
    },
    totalVisible: {
      default: 6,
    },
  },

  computed: {
    getResult() {
      if (this.data.total > 0) {
        return true;
      }

      return false;
    },

    plural() {
      if (this.data.total > 1) {
        return "s";
      }

      return "";
    },

    hasPages() {
      if (this.data.last_page > 1) {
        return true;
      }

      return false;
    },

    appPaginationStyle() {
      return this.$vuetify.breakpoint.mobile
        ? "app-pagination-mobile"
        : "app-pagination-desktop";
    },
  },
};
</script>

<style lang="sass" scoped>
#app-pagination-desktop
  .icone
    width: 134px
    height: 130px

#app-pagination-mobile
  .icone
    width: 110px
    height: 108px
</style>
