<template>
  <v-bottom-navigation v-if="$vuetify.breakpoint.mobile" grow app>
    <v-btn plain @click.prevent="$emit('toggle-drawer')">
      <app-icon>menu</app-icon>
    </v-btn>

    <v-btn plain active-class="btn-active" value="dashboard" to="/">
      <app-icon>dashboard</app-icon>
    </v-btn>

    <v-btn plain active-class="btn-active" value="agenda" to="/agenda">
      <app-icon>event</app-icon>
    </v-btn>

    <v-btn plain active-class="btn-active" value="clientes" to="/clientes">
      <app-icon>people</app-icon>
    </v-btn>

    <v-btn
      v-if="$acl.canViewTransactions()"
      plain
      active-class="btn-active"
      value="financeiro"
      to="/financeiro"
    >
      <app-icon>monetization_on</app-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
.btn-active
  border-top: 3px solid #907FF5
  i.v-icon
    color: #907FF5 !important
</style>