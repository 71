<template>
  <v-snackbar
    :multi-line="true"
    v-model="alert"
    :color="notification.data.type"
    absolute
    right
    outlined
    top
  >
    <template v-slot:default>
      <v-row class="d-flex align-center justify-center">
        <v-icon
          class="mx-2 my-auto"
          size="40px"
          :color="notification.data.type"
          v-text="notification.data.icon"
        />
        <v-col>
          <v-row class="font-weight-bold">{{ notification.data.title }}</v-row>
          <v-row>{{ notification.data.message }} </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-slot:action="{ attrs }">
      <v-btn small text icon v-bind="attrs" @click="alert = false">
        <v-icon>mdi-close </v-icon>
      </v-btn> </template
    >''
  </v-snackbar>
</template>
<script>
export default {
  data: () => ({
    alert: false,
    timeout: 5000,
  }),

  computed: {
    notification() {
      return this.$store.state.notification.unreadNotification;
    },
  },

  watch: {
    notification(val) {
      setTimeout(() => (this.alert = true), 2000);
    },
  },
};
</script>