<template>
  <div>
    <template v-if="hasImage">
      <v-avatar :size="size">
        <v-img :src="getImage(src)" contain />
      </v-avatar>
    </template>
    <template v-else>
       <v-icon :size="size" class="material-symbols-rounded">account_circle</v-icon>
    </template>
  </div>
</template>

<script>
import MediaMixin from "@/mixins/Media/MediaMixin";

export default {
  mixins: [MediaMixin],

  props: {
    src: {
      default: null,
    },
    size: {
      default: 30,
    },
  },

  computed: {
    hasImage() {
      return this.src !== null;
    },
  },
};
</script>

<style></style>
